import { FaHome, FaTicketAlt, FaTasks, FaBox, FaBan, FaChartBar, FaUser,FaLock,FaRupeeSign } from 'react-icons/fa';
import { TbReportSearch } from "react-icons/tb";
import { GiAutoRepair } from "react-icons/gi";
import { MdOutlinePayment } from "react-icons/md";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import { GrServices } from "react-icons/gr";

export const MENUITEMS = [
  {
    Items: [
      {
        title: "Dashboards",
        icon: FaHome,
        type: "link",
        path: "dashboard",
      },
      {
        title: "Tickets",
        icon: FaTicketAlt,
        type: "link",
        path: "tickets",
      },
      {
        title: "Our Aasra",
        icon: "home",
        type: "link",
        path: "add-aasra",
      },
      {
        title: "Inventory Reports",
        icon: TbReportSearch,
        type: "link",
        path: "inventory-reports",
      },
      {
        title: "Repair Reports",
        icon: GiAutoRepair,
        type: "link",
        path: "repair-reports",
      },
      {
        title: "Revenue Reports",
        icon: FaRupeeSign,
        type: "link",
        path: "revenue-reports",
      },
      {
        title: "Payment Reports",
        icon: "home",
        type: "link",
        path: "payment-reports",
      },
      {
        title: "Replacement Reports",
        icon: "home",
        type: "link",
        path: "parts-replacement-report",
      },
      {
        title: "Stock Reports",
        icon: "home",
        type: "link",
        path: "as-a-whole-stock-report",
      },
      {
        title: "Payment",
        icon:  MdOutlinePayment,
        type: "link",
        path: "payment-list",
      },
      {
        title: "Users",
        icon: FaUser,
        type: "sub",
        children: [
          {
            active: false,
            path: `user/add-user`,
            title: "User",
            type: "link",
          },
        ],
      },
      {
        title: "Purchase",
        icon: BiSolidPurchaseTagAlt,
        type: "sub",
        children: [         
          {
            active: false,
            path: `purchase/all-purchase`,
            title: "All Purchase",
            type: "link",
          },
        ],
      },
      {
        title: "Service Charge (W.P)",
        icon: GrServices,
        type: "link",
        path: "service-charge-w-p",
      },
      {
        title: "Master",
        icon: "sample-page",
        type: "sub",
        children: [
          {
            active: false,
            path: `master/uom`,
            title: "UOM",
            type: "link",
          },
          {
            active: false,
            path: `master/category`,
            title: "Category",
            type: "link",
          },
          {
            active: false,
            path: `master/labour-charge`,
            title: "Labour Charge",
            type: "link",
          },
          {
            active: false,
            path: `master/spare-parts`,
            title: "Spare Parts",
            type: "link",
          },
        ],
      },
    ],
  },
];

export const AASRAMENU = [
  {
    Items: [
      {
        title: "Dashboards",
        icon: "home",
        type: "link",
        path: "dashboard",
      },
      {
        title: "Tickets",
        icon: "task",
        type: "link",
        path: "tickets",
      },
      {
        title: "Inventory Reports",
        icon: "home",
        type: "link",
        path: "inventory-reports",
      },
      {
        title: "Revenue Reports",
        icon: "home",
        type: "link",
        path: "revenue-reports",
      },
      {
        title: "Payment Reports",
        icon: "home",
        type: "link",
        path: "payment-reports",
      },
      {
        title: "Replacement Reports",
        icon: "home",
        type: "link",
        path: "parts-replacement-report",
      },
      {
        title: "Stock Reports",
        icon: "home",
        type: "link",
        path: "as-a-whole-stock-report",
      },
      {
        title: "Transaction",
        icon: "home",
        type: "link",
        path: "payment-list",
      },

      {
        title: "Purchase",
        icon: "home",
        type: "sub",
        children: [
          {
            active: false,
            path: `purchase/create-purchase`,
            title: "Create Purchase",
            type: "link",
          },
          {
            active: false,
            path: `purchase/all-purchase`,
            title: "All Purchase",
            type: "link",
          },
        ],
      },
    ],
  },
];
