import logo from "../assets/images/logo/logo_invoice.jfif";
import watermark from "../assets/images/logo/Alimco-job.png";
import electricLogo from "../assets/images/logo/alimco-elctric-logo.png";
import waterMarkJob from "../assets/images/logo/Alimco-job.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ToWords } from "to-words";
import { RUPEES_SYMBOL } from "../Constant";
import { useState } from "react";

export const printJobCard1 = (row) => {
    const ticketDetailsRows = row?.ticketDetail
        ?.map(
            (item, index) => `
    <tr key="${index}"  style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${index + 1
                }</td>
      
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.categoryLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.productLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.repairLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.old_serial_number
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.new_serial_number
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.qty
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.price
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.serviceCharge
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${item.qty * item.price + item.serviceCharge
                }</td>
    </tr>
`
        )
        .join("");
    const jobDetailsRows = row?.ticketDetail
        ?.map(
            (item, index) => `
    <tr key="${index}"  style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${index + 1
                }</td>
      
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.repairLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.serviceCharge
                }</td>
         </tr>
`
        )
        .join("");

    const totalAmount = row?.ticketDetail.reduce(
        (total, item) => total + item.serviceCharge,
        0
    );

    const printWindow = window.open();
    printWindow.document.write(
        `
        <!DOCTYPE html>
        <html lang="en">
       <head>
      <meta charset="utf-8">
      <title> Artificial Limbs Manufacturing Corporation of India</title>
       <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 20px;
            background-color: #f4f4f4;
        }
        .container {
            background-color: #fff;
            padding: 20px;
            margin: 0 auto;
            border: 1px solid #ccc;
            max-width: 800px;
        }
        .header, .footer {
            text-align: center;
            margin-bottom: 20px;
        }
        .header img {
            max-width: 100px;
        }
        .header h1, .header h2 {
            margin: 5px 0;
        }
        .details, .job-description, .labour-charges, .spare-parts {
            margin-bottom: 10px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 5px;
        }
        table, th, td {
            border: 1px solid #000;
            padding: 5px;
            text-align: left;
        }
        th {
            background-color: #f2f2f2;
        }
    </style>
  </head>
  <body style="margin: 10px 0; font-size: 12px;line-height: 16px; ">
 <div class="container">
    <div class="header" style="display: flex;justify-content: center;">
       <div>
        <img src=${logo} alt="ALIMCO Logo">
       </div>
        <div>
        <h2 style="margin: 5px 0px;">ALIMCO AUTHORISED SERVICE AND REPAIR AGENT, (AASRA)</h2>
        <h4 style="margin: 5px 0px;">BAHADURGARH, TIKAMGARH, MADHYAPRADESH-472001</h4>
        <span>PHON NO-6268131939</span>
        <h3>JOB-CARD</h3>
        </div>
        
    </div>
    <div>
    
    </div>

    <div class="details">
     <div style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(360deg);
                font-size: 12em;
                color: rgba(233, 226, 226, 0.13);
                z-index: 0;
                pointer-events: none; /* Ensures watermark does not interfere with table content */
                font-family: 'Denk One', sans-serif;
                text-transform: uppercase;
                text-align: center;
                width: 100%;
            ">
                <img src=${watermark}
                alt="alimco-logo" 
                width="180px"
                style="opacity: 0.2;">
            </div>
        <table>
            <tr>
                <th>SL. No.</th>
                <td>${row?.sr_no}</td>
                <th>Service Order NO.</th>
                <td>${row?.ticket_id}</td>
            </tr>
            <tr>
                <th>Customer Name</th>
                <td>${row?.customer_name}</td>
                <th>Date</th>
                <td></td>
            </tr>
            <tr>
                <th>Customer Address</th>
                <td></td>
                <th>Mobile No.</th>
                <td></td>
            </tr>
            <tr>
                <th>Adhar No.</th>
                <td></td>
                <th>Date of Distribution</th>
                <td></td>
            </tr>
        </table>
    </div>

    <div class="job-description">
        <h3 style="margin: 5px 0px;">Job Description</h3>
        <table>
            <tr>
                <td style="height: 100px;">${row?.description}</td>
            </tr>
        </table>
    </div>

    <div class="labour-charges">
        <h3 style="margin: 5px 0px;">Job Description</h3>
        <table>
            <tr>
                <th>SL. No.</th>
                <th>Description of Goods</th>
                <th>Labour Rate (in Rs)</th>
            </tr>
           ${jobDetailsRows}
           <tr>
           <td></td>
           <th style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">Total Labour Charge</th>
             <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${totalAmount}</td>
           </tr>
        </table>
    </div>

    <div class="spare-parts">
        <h3 style="margin: 5px 0px;">Spare Parts</h3>
        <table>
            <tr>
                <th>Quantity</th>
                <th>Category</th>
                <th>Spare Part</th>
                <th>Description of Goods</th>
                <th>Old Sr.No.</th>
                <th>New Sr.No.</th>
                <th>Qty</th>
                <th>Basic Price</th>
                <th>Service Charge</th>
                <th>Amount</th>
            </tr>
           ${ticketDetailsRows}
           <tr>
         
        </table>
    </div>

    
        
        <table>
            <tr>
                <th>Total Labour Charge</th>
                <td>${row?.serviceCharge}</td>
            </tr>
            <tr>
                <th>Total Spare Cost</th>
                <td>${row?.subtotal}</td>
            </tr>
            <tr>
                <th>GST</th>
                <td>${row?.gst}</td>
            </tr>
            <tr>
                <th>Total</th>
                <td>${row?.totalAmount}</td>
            </tr>
        </table>

<table>
<tr>
<td style="font-size: 14px;">Job done by AASRA Operator Signature</td>
<td style="font-size: 14px;">Customers/Beneficiary Signature</td>
<td style="font-size: 14px;">Job Verified by ALIMCO service Deptt.</td>
</tr>
</table>


</div>
  </body>
  </html>
  `
    );
    printWindow.focus();
    printWindow.print();
};

export const printJobCard = (row) => {
    let calc_discount = 0;
    let discountedAmt = 0;
    let totalAmount = 0; // Initialize to calculate total cost
    let productPrices = []; // Array to store productPrice values
    const fixedPrice = row?.ticketDetail[0]?.repairPrice || 0;
    const ticketDetailsRows = row?.ticketDetail
        ?.map(
            (item, index) => `
    <tr key="${index}"  style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${index + 1
                }</td>
      
      
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${item.productLabel
                }</td>
       
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${item.qty
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${item.repairCheckLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${item.productPrice
                }</td>
       
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

        ${RUPEES_SYMBOL}${(item.qty * item.productPrice)?.toFixed(2) || 0.00}</td>
    </tr>
`
        )
        .join("");
    const jobDetailsRows = row?.ticketDetail
        ?.map(
            (item, index) => `
    <tr key="${index}"  style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
        <td  style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">${index + 1
                }</td>
        <td  style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">${item.repairLabel
                }</td>
        <td  style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center">${item.serviceCharge
                }</td>
         </tr>
`
        )
        .join("");

    //   const totalAmount = row?.ticketDetail.reduce(
    //     (total, item) => total +(row.qty*item?.price+ item.serviceCharge),
    //     0
    //   );

    let totalLabourAmount = row?.ticketDetail.reduce((total, item) => {
        const fixedCharge = fixedPrice;
        const itemTotal = total + item.qty * item.serviceCharge;
        // console.log("5555", itemTotal, total, fixedCharge);

        return itemTotal || 0.00;
    }, 0);

    //   console.log(totalLabourAmount, "ttttt");
    if (row?.warranty === true) {
        if (row?.ticketDetail.length > 0) {
            row?.ticketDetail.forEach((ticket) => {
                const productPrice = ticket.productPrice;
                const qty = ticket.qty;

                productPrices.push(productPrice); // Add product price to the array

                // Check the condition for repair value
                if (ticket.repairCheckValue === "Purchase") {
                    // No discount for purchases
                    calc_discount += productPrice * qty * (0 / 100); // No discount
                    totalAmount += productPrice * qty; // Include the product price with the quantity
                } else {
                    // Apply 100% discount for repairs
                    calc_discount += productPrice * qty * (100 / 100); // 100% discount for repairs
                    totalAmount += 0; // Total amount for repairs should be zero
                }
            });
        }

        // console.log("All Product Prices:", productPrices); // This will log the array with all product prices
        // console.log("Total Discount Amount", calc_discount);

        // discountedAmt = totalAmount - calc_discount; // Calculate the final amount after discount
        // discountedAmt = Math.max(0, discountedAmt);
        // console.log("Total payable Amount", discountedAmt);
    } else {
        // If out of warranty, no discount for any of them
        // console.log("No discount applied, warranty is false.");
        discountedAmt = totalAmount; // No discount applied
        // console.log("Discounted Amount without discount", discountedAmt);
    }

    // Calculate the spare amount based on qty * productPrice for all items
    const spareAmount = row?.ticketDetail.reduce((total, item) => {
        // If it's a repair, the value is 0 (100% discount)
        return (
            total +
            (item.repairCheckValue === "Repair" ? 0 : item.qty * item.productPrice)
        );
    }, 0);

    //   console.log("Spare Amount:", spareAmount);

    // Ensure `discountedAmt` is valid before calculating `grandTotal`
    // Ensure calc_discount has a valid value
    const validDiscount = calc_discount || 0;

    // Check if spareAmount has a valid value (greater than 0)
    let grandTotal = 0;
    if (spareAmount > 0) {
        grandTotal = spareAmount - validDiscount;
    } else {
        grandTotal = validDiscount;
    }

    // Ensure the grandTotal is not negative
    grandTotal = Math.max(0, grandTotal);

    //   console.log("Grand Total:", grandTotal);

    const total = row?.totalAmount || 0.00; // Default to 0 if undefined or null
    const gstAmount = row?.gstAmount || 0.00; // Default to 0 if undefined or null

    // Calculate the sum
    const totalWithGst = total + gstAmount;

    const formattedOutput = `${totalWithGst.toFixed(2) || 0.00}`; // This will format to 2 decimal places
    const printWindow = window.open('', '_blank');
    printWindow.document.write(
        `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>ALIMCO AUTHORISED SALES AND REAPPEARING AGENCY, (AASRA)</title>
  <style>
                @media print {
                  @page {
                    size: A4 portrait;
                    margin: 50px;
                  }
                  .page-break {
                    page-break-before: always;
                  }
                  .no-page-break {
                    page-break-inside: avoid;
                  }
                }
                body {
                  font-family: Calibri, sans-serif;
                  font-size: 14px;
                  margin: 0;
                  padding: 0;
                }
                .section {
                  margin-bottom: 20px; /* Adjust if needed */
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                  .subpage {
             padding: 15px;
        box-sizing: border-box;
        }
        @media print {
    @page {
        margin: 0;
        size: A4;
        background: url(${waterMarkJob}) center center no-repeat;
        background-size: cover;
    }

    body {
        margin: 0;
        padding: 0;
    }

    #body_TblHeader {
        border: solid 0px #000;
        margin: auto;
        width: 740px;
    }
}
              </style>
</head>
<body style="margin: 10px 0; font-size: 12px;line-height: 16px;">
    <div class="page">
        <div class="subpage">
            <div style="padding: 2px; height: -webkit-fill-available;">
                <table cellpadding="0" cellspacing="0" id="body_TblHeader"
                    style="border: solid 0px #000; font-family: Calibri; 
                    font-size: 14px; margin: auto; 
                    width: 740px;
                    background:url(${waterMarkJob})
                    ;
                    background-size: cover;
        background-position: center;">
                    <tr>
                        <td colspan="">
                            <table cellpadding="0" cellspacing="0" width="100%">
                             <thead>
                                        <tr>
                                            <td colspan="2" style="padding-bottom: 2px;">
                                                <table cellpadding="0" cellspacing="0" width="100%">
                                                    <tr>
                                                        <td width="20%"
                                                            style="border-bottom:0px solid #000; text-align:center;">
                                                            <img src=${logo}
                                                                alt="Greenfin Ascent Private Limited"
                                                                style="width:100px;">
                                                        </td>
                                                        <td width="80%"
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 10px;">
                                                            <strong style="font-size: 22px;">ALIMCO AUTHORISED SERVICE
                                                                AND
                                                                REPAIR AGENT, (AASRA)</strong><br>
                                                            <strong style="font-size: 16px;">${row?.aasraName
        } , ${row?.address
        }</strong>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                  
                                    <tr>
                                        <td colspan="2" style="border-bottom:0px solid #cbcbcb; text-align:center;">
                                            <span><b style="font-size: 20px;">JOB-CARD</b></span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="width: 50%;padding: 5px 0px;">
                                            <table cellpadding="0" cellspacing="0" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;width: 35%;">
                                                            SL. No.</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            ${row?.sr_no}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            Customer Name</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            ${row?.customer_name
        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            Customer Address</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            ${row?.address || ""
        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            Adhar No.</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                           ${row?.aadhaar || ""}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                        <td style="width: 50%;">
                                            <table cellpadding="0" cellspacing="0" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            Service Order No.</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            ${row?.ticket_id}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            Date</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            ${row?.createdAt ||
        ""
        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            Mobile No.</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            ${row?.mobile}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                            Date of Distribution</td>
                                                        <td
                                                            style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                           ${row?.dstDate || ""}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2" style="border: 1px solid #9f9b9b;">
                                            <table style="width: 100%;" cellpadding="0" cellspacing="0">
                                                <tr>
                                                    <td style="height: 100px;vertical-align: top;">


                                                       <b>Customer Complaint</b> : <span>${row?.description || ""
        }</span>                                                       

                                                        <p style="border-bottom: 1px solid #afadad;"></p>
                                                        <p style="border-bottom: 1px solid #afadad;"></p>
                                                        <p style="border-bottom: 1px solid #afadad;"></p>
                                                        <p style="border-bottom: 1px solid #afadad;"></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style="text-align: center;font-size: 16px;background: #9f9b9b;">
                                                        Job Description</th>
                                                </tr>
                                                <tr>
                                                    <td style="height: 120px;vertical-align: top;">
                                                     <p style="border-bottom: 1px solid #afadad;"></p>
                                                    ${row?.job_description || ""
        }
                                                        <p style="border-bottom: 1px solid #afadad;"></p>
                                                        <p style="border-bottom: 1px solid #afadad;"></p>
                                                        <p style="border-bottom: 1px solid #afadad;"></p>
                                                        
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                   </thead>                 
 <tbody>
                                    <tr>
                                        <td colspan="2" style="padding-top: 5px;height: 490px;vertical-align: top;">
                                            <table style="width: 100%;" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;width: 50px;text-align: center;">
                                                            Sr. No.</th>
                                                        <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                            Labour Rate (in Rs)</th>
                                                        <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;">
                                                            Amount</th>
                                                    </tr>
                                                    ${jobDetailsRows}                                                  
                                                    <tr>
                                                        <th colspan="2"
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                            Total Labour Charge</th>
                                                        <td
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                                                              ${RUPEES_SYMBOL} ${totalLabourAmount || 0.00}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table style="width: 100% ;padding-top: 5px;" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Sr. No.</th>
                                                       
                                                        <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Spare Part</th>
                                                       <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Quantity</th>
                                                        <th
                                                       <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Repairing and Handling</th>
                                                        <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Basic Price</th>
                                                         <th
                                                            style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center">Amount</th>
                                                    </tr>       
                                                   ${ticketDetailsRows}
                                                    <tr>
                                                        <th
                                                            style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center" colspan="5">Total Spare Part</th>
                                                        <td
                                                            style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center">${RUPEES_SYMBOL} ${spareAmount?.toFixed(
            2
        ) || 0.00}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    




                                </tbody>
                                <tfoot>
                                 <tr>
                                        <table style="width: 100%;">
                                            <tr>
                                                <td style="width: 60%;">
                                                    <table>
                                                        <tr>
                                                            <td style="width: 40%;">Job done by AASRA<br> Operator Signature</td>
                                                            <td style="width: 32%;">Customers/Beneficiary<br> Signature</td>
                                                            <td>Job Verified by ALIMCO<br> service Deptt.</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td colspan="1" style="padding-top: 5px;width: 40%;">
                                                    <table style="width: 100%;" cellpadding="0" cellspacing="0">
                                                        <tbody>
                                                          <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                    Total Spare Cost</th>
                                                                     <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                               ${RUPEES_SYMBOL} ${row?.total.toFixed(2) || 0.00 //   row?.subtotal?.toFixed(2)
        } </td>
                                                               
                                                            </tr>
                                                             
                                                            <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;width: 50%;">
                                                                    Total Labour Charge</th>
                                                                <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                              ${RUPEES_SYMBOL} ${row?.serviceCharge || 0.00
        } </td>
                                                            </tr>
                                                             <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;width: 50%;">
                                                                    GST Amount</th>
                                                                <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                              ${RUPEES_SYMBOL} ${row?.gstAmount.toFixed(2) || 0.00
        } </td>
                                                            </tr>
                                                             <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                    Discount</th>
                                                                     <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                               ${RUPEES_SYMBOL} ${row?.discount || 0.00
        } </td>
                                                               
                                                            </tr>
                                                           
                                                              <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                    Additional Discount</th>
                                                                     <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                               ${RUPEES_SYMBOL} ${parseFloat(row?.additionalDiscount).toFixed(2) || 0.00
        } </td>
                                                               
                                                            </tr>
                                                           
                                                            <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                    Total</th>
                                                                     <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                               ${RUPEES_SYMBOL} ${formattedOutput || 0.00} </td>
                                                               
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </tr>
                                </tfoot>
                            </table>
                        </td>
                    </tr>


                </table>
            </div>
        </div>
    </div>

</body>

</html>`
    );
    printWindow.print();
    // printWindow.close();
    // printWindow.document.close(); // necessary for some browsers to finish writing before printing
    // setTimeout(() => {
    //     printWindow.print();
    //     printWindow.close();
    // }, 2000)

};

export const printJobCardBulk = (rowData) => {
    //   console.log("rowData:", rowData);
    //    return false;
    // Ensure rowData is an array and has at least one item
    if (!Array.isArray(rowData)) {
        alert("No data available to print.");
        return;
    }

    // Function to process rowData and return HTML and calculated amounts
    function processTicketDetails(rowData) {
        let totalAmount = 0;
        let spareAmount = 0;

        // Map ticketDetails for table rows
        const ticketDetailsRows = rowData.ticketDetail
            ?.map((item, index) => {
                const rowTotal = item.qty * item.productPrice;
                spareAmount += rowTotal;

                return `
          <tr key="${index}" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px; font-size: 13px;">
            <td style="border: 1px solid #afadad; text-align: center;">${index + 1
                    }</td>
            <td style="border: 1px solid #afadad; text-align: center;">${item.productLabel
                    }</td>
            <td style="border: 1px solid #afadad; text-align: center;">${item.qty
                    }</td>
            <td style="border: 1px solid #afadad; text-align: center;">${item.repairCheckLabel
                    }</td>
            <td style="border: 1px solid #afadad; text-align: center;">${item.productPrice
                    }</td>
            <td style="border: 1px solid #afadad; text-align: center;">${RUPEES_SYMBOL}${rowTotal.toFixed(
                        2
                    ) || 0.00}</td>
          </tr>
        `;
            })
            .join("");

        // Map jobDetails for table rows
        const jobDetailsRows = rowData.ticketDetail
            ?.map((item, index) => {
                const serviceTotal = item.qty * item.serviceCharge;
                totalAmount += serviceTotal;

                return `
          <tr key="${index}" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px; font-size: 13px;">
            <td style="border: 1px solid #afadad; text-align: center;">${index + 1
                    }</td>
            <td style="border: 1px solid #afadad; text-align: center;">${item.repairLabel
                    }</td>
            <td style="border: 1px solid #afadad; text-align: center;">${item.serviceCharge
                    }</td>
          </tr>
        `;
            })
            .join("");

        // Calculate the discount
        const discountPercentage = rowData.ticketDetail.some(
            (item) => item.repairCheckValue === "Purchase"
        )
            ? 0
            : 100;

        const discountAmount =
            (totalAmount + spareAmount) * (discountPercentage / 100);
        const finalAmount = totalAmount + spareAmount - discountAmount;
        // Return the mapped HTML content and calculated amounts
        return {
            ticketDetailsRows,
            jobDetailsRows,
            totalAmount,
            spareAmount,
            discountAmount,
            finalAmount, // This includes the total after applying discount
        };
    }

    // Start HTML content with the header and styles
    let htmlContent = `
          <!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="utf-8">
              <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <meta name="description" content="">
              <meta name="author" content="">
              <title>ALIMCO</title>
              <style>
                @media print {
                  @page {
                    size: A4 portrait;
                    margin: 50px;
                  }
                  .page-break {
                    page-break-before: always;
                  }
                  .no-page-break {
                    page-break-inside: avoid;
                  }
                }
                body {
                  font-family: Calibri, sans-serif;
                  font-size: 14px;
                  margin: 0;
                  padding: 0;
                }
                .section {
                  margin-bottom: 20px; /* Adjust if needed */
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                  .subpage {
             padding: 15px;
        box-sizing: border-box;
        }
              </style>
            </head>
            <body style="margin: 10px 0; font-size: 12px;line-height: 16px;">
        `;

    // Loop through rowDataData to generate content for each row
    rowData.forEach((row, index) => {
        const total = row?.totalAmount || 0.00; // Default to 0 if undefined or null
        const gstAmount = row?.gstAmount || 0.00; // Default to 0 if undefined or null

        // Calculate the sum
        const totalWithGst = total + gstAmount;

        const formattedOutput = `${totalWithGst.toFixed(2) || 0.00}`; // This will format to 2 decimal places

        // Process the ticket details and get the HTML and calculated values
        const { ticketDetailsRows, jobDetailsRows, totalAmount, spareAmount } =
            processTicketDetails(row);
        htmlContent += `
      <div class="page">
          <div class="subpage" style="background:url(${waterMarkJob});
     background-size: contain;
    background-position: center;
    background-repeat: no-repeat;">
              <div style="padding:2px; height: -webkit-fill-available;">
                  <table cellpadding="0" cellspacing="0" id="body_TblHeader"
                      style="border: solid 0px #000; font-family: Calibri; font-size: 14px; margin: auto; width: 740px;">
                      <tr>
                          <td colspan="">
                              <table cellpadding="0" cellspacing="0" width="100%">
                               <thead>
                                          <tr>
                                              <td colspan="2" style="padding-bottom: 2px;">
                                                  <table cellpadding="0" cellspacing="0" width="100%">
                                                      <tr>
                                                          <td width="20%"
                                                              style="border-bottom:0px solid #000; text-align:center;">
                                                              <img src=${logo}
                                                                  alt="Greenfin Ascent Private Limited"
                                                                  style="width:100px;">
                                                          </td>
                                                          <td width="80%"
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 10px;">
                                                              <strong style="font-size: 22px;">ALIMCO AUTHORISED SERVICE
                                                                  AND
                                                                  REPAIR AGENT, (AASRA)</strong><br>
                                                              <strong style="font-size: 16px;">${row?.aasraName
            } , ${row?.address
            }</strong>
                                                          </td>
                                                      </tr>
                                                  </table>
                                              </td>
                                          </tr>
                                      </thead>
                                  <tbody>
                                      
                                      <tr>
                                          <td colspan="2" style="border-bottom:0px solid #cbcbcb; text-align:center;">
                                              <span><b style="font-size: 20px;">JOB-CARD</b></span>
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td style="width: 50%;padding: 5px 0px;">
                                              <table cellpadding="0" cellspacing="0" width="100%">
                                                  <tbody>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;width: 35%;">
                                                              SL. No.</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              ${row?.sr_no}
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              Customer Name</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              ${row?.customer_name
            }
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              Customer Address</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              ${row?.address ||
            ""
            }
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              Adhar No.</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                             ${row?.aadhaar ||
            ""
            }
                                                          </td>
                                                      </tr>
  
                                                  </tbody>
                                              </table>
                                          </td>
                                          <td style="width: 50%;">
                                              <table cellpadding="0" cellspacing="0" width="100%">
                                                  <tbody>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              Service Order No.</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              ${row?.ticket_id}
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              Date</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              ${row?.createdAt ||
            ""
            }
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              Mobile No.</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              ${row?.mobile}
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                              Date of Distribution</td>
                                                          <td
                                                              style="border-bottom:0px solid #cbcbcb; text-align:left; padding-left: 0px;">
                                                             ${row?.dstDate ||
            ""
            }
                                                          </td>
                                                      </tr>
  
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td colspan="2" style="border: 1px solid #9f9b9b;">
                                              <table style="width: 100%;" cellpadding="0" cellspacing="0">
                                                  <tr>
                                                      <td style="height: 100px;vertical-align: top;">
  
  
                                                         <b>Customer Complaint</b> : <span>${row?.description ||
            ""
            }</span>                                                       
  
                                                          <p style="border-bottom: 1px solid #afadad;"></p>
                                                          <p style="border-bottom: 1px solid #afadad;"></p>
                                                          <p style="border-bottom: 1px solid #afadad;"></p>
                                                          <p style="border-bottom: 1px solid #afadad;"></p>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <th style="text-align: center;font-size: 16px;background: #9f9b9b;">
                                                          Job Description</th>
                                                  </tr>
                                                  <tr>
                                                      <td style="height: 120px;vertical-align: top;">
                                                       <p style="border-bottom: 1px solid #afadad;"></p>
                                                      ${row?.job_description ||
            ""
            }
                                                          <p style="border-bottom: 1px solid #afadad;"></p>
                                                          <p style="border-bottom: 1px solid #afadad;"></p>
                                                          <p style="border-bottom: 1px solid #afadad;"></p>
                                                          
                                                      </td>
                                                  </tr>
                                              </table>
                                          </td>
                                      </tr>
  
                                      
  
                                      <tr>
                                          <td colspan="2" style="padding-top: 5px;">
                                              <table style="width: 100%;" cellpadding="0" cellspacing="0">
                                                  <tbody>
                                                      <tr>
                                                          <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;width: 50px;text-align: center;">
                                                              Sr. No.</th>
                                                          <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                              Labour Rate (in Rs)</th>
                                                          <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;">
                                                              Amount</th>
                                                      </tr>
                                                      ${jobDetailsRows}                                                  
                                                      <tr>
                                                          <th colspan="2"
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                              Total Labour Charge</th>
                                                          <td
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                                                                ${RUPEES_SYMBOL} ${totalAmount?.toFixed(
                2
            ) || 0.00}
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
  
                                      
  
                                      <tr>
                                          <td colspan="2" style="padding-top: 5px; height:250px;vertical-align:top">
                                              <table style="width: 100%;" cellpadding="0" cellspacing="0">
                                                  <tbody>
                                                      <tr>
                                                          <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Sr. No.</th>
                                                         
                                                          <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Spare Part</th>
                                                         <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Quantity</th>
                                                          <th
                                                         <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Repairing and Handling</th>
                                                          <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center">Basic Price</th>
                                                           <th
                                                              style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center">Amount</th>
                                                      </tr>       
                                                     ${ticketDetailsRows}
                                                      <tr>
                                                          <th
                                                              style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;text-align: center" colspan="5">Total Spare Part</th>
                                                          <td
                                                              style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center">${RUPEES_SYMBOL} ${spareAmount?.toFixed(
                2
            ) || 0.00}</td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                        <tr>
                                        <tfoot>
                                          <table style="width: 100%;">
                                              <tr>
                                                  <td style="width: 60%;">
                                                      <table>
                                                          <tr>
                                                              <td style="width: 40%;">Job done by AASRA<br> Operator Signature</td>
                                                              <td style="width: 32%;">Customers/Beneficiary<br> Signature</td>
                                                              <td>Job Verified by ALIMCO<br> service Deptt.</td>
                                                          </tr>
                                                      </table>
                                                  </td>
                                                  <td colspan="1"width: 40%;">
                                                      <table style="width: 100%;" cellpadding="0" cellspacing="0">
                                                          <tbody>
                                                            <tr>
                                                                  <th
                                                                      style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                      Total Spare Cost</th>
                                                                       <td
                                                                      style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
  
                                                                 ${RUPEES_SYMBOL} ${row?.total.toFixed(2) || 0.00 //   row?.subtotal?.toFixed(2)
            } </td>
                                                                 
                                                              </tr>
                                                               
                                                              <tr>
                                                                  <th
                                                                      style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;width: 50%;">
                                                                      Total Labour Charge</th>
                                                                  <td
                                                                      style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
  
                                                                ${RUPEES_SYMBOL} ${row?.serviceCharge || 0.00
            } </td>
                                                              </tr>
                                                             <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;width: 50%;">
                                                                    GST Amount</th>
                                                                <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 0px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                              ${RUPEES_SYMBOL} ${row?.gstAmount.toFixed(2) || 0.00
            } </td>
                                                            </tr>
                                                                                                                          <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                    Discount</th>
                                                                     <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                               ${RUPEES_SYMBOL} ${row?.discount || 0.00
            } </td>
                                                               
                                                            </tr>
                                                                                                                        <tr>
                                                                <th
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                   Additional Discount</th>
                                                                     <td
                                                                    style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">

                                                               ${RUPEES_SYMBOL} ${row?.additionalDiscount || 0.00
            } </td>
                                                               
                                                            </tr>
                                                              <tr>
                                                                  <th
                                                                      style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 0px solid #afadad;">
                                                                      Total</th>
                                                                       <td
                                                                      style="border-top: 1px solid #afadad;border-bottom: 1px solid #afadad;border-left: 1px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
  
                                                                 ${RUPEES_SYMBOL} ${formattedOutput} </td>
                                                                 
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </table>
                                          </tfoot>
                                      </tr>
  
  
  
                                  </tbody>
                              </table>
                          </td>
                      </tr>
  
  
                  </table>
              </div>
          </div>
      </div>
  ${index < rowData.length - 1 ? '<div class="page-break"></div>' : ""}
            `;
    });

    // Close HTML content
    htmlContent += `
            </body>
          </html>
        `;

    // Open a new window and print the document
    const printWindow = window.open();

    if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(htmlContent);
        //   printWindow.document.close();
        // printWindow.onload = async () => {
        //     try {
        //         // Use html2canvas to capture the content as an image
        //         const canvas = await html2canvas(printWindow.document.body, {
        //             scale: 2, // Adjust scale as needed for better resolution
        //             useCORS: true, // Ensure that cross-origin images are handled
        //         });

        //         // Get the data URL of the canvas (image)
        //         const imageData = canvas.toDataURL("image/png");

        //         // Open the image in a new tab for demonstration (optional)
        //         const newTab = window.open();
        //         newTab.document.write('<img src="' + imageData + '"/>');

        //         // Trigger the print dialog after a short delay
        //         setTimeout(() => {
        //             printWindow.print();
        //         }, 3000); // Adjust delay if necessary
        //     } catch (error) {
        //         // console.error("Error capturing content with html2canvas:", error);
        //     }
        // };
        // Trigger the print dialog
        // printWindow.print();
        // printWindow.close();
        printWindow.document.close(); // necessary for some browsers to finish writing before printing
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000)
    } else {
        alert(
            "Failed to open print window. Please check your browser's pop-up settings."
        );
    }
};

export const handleClosedTicketPrintBulk = (rowData) => {
    //   console.log("rowData:", rowData);

    let calc_discount = 0;
    let discountedAmt = 0;
    let totalAmount = 0; // Initialize to calculate total cost
    let productPrices = []; // Array to store productPrice values

    // Ensure rowData is an array and has at least one item
    if (!Array.isArray(rowData) || rowData?.length === 0) {
        alert("No data available to print.");
        return;
    }

    //   const calculateTotalsForRows = (rows = []) => {
    //     let overallSpareAmount = 0;
    //     let overallDiscountedAmt = 0;
    //     let overallGrandTotal = 0;

    //     if (!Array.isArray(rows)) {
    //         console.error('Expected rows to be an array, received:', rows);
    //         return {
    //             overallSpareAmount,
    //             overallDiscountedAmt,
    //             overallGrandTotal,
    //         };
    //     }
    //     if (rows?.length === 1) {
    //         console.log('Single item in rows:', rows[0]);
    //     }
    //     rows?.forEach((row) => {
    //         const fixedPrice = row?.ticketDetail[0]?.repairPrice || 0;
    //         let calc_discount = 0;
    //         let totalAmount = 0;
    //         let productPrices = [];

    //         const spareAmount = row?.ticketDetail.reduce((total, item) => {
    //             return total + (item.repairCheckValue === "Repair" ? 0 : item.qty * item.productPrice);
    //         }, 0);

    //         let totalLabourAmount = row?.ticketDetail.reduce((total, item) => {
    //             return total + item.qty * item.serviceCharge;
    //         }, 0);

    //         if (row?.warranty === true) {
    //             if (row?.ticketDetail.length > 0) {
    //                 row.ticketDetail.forEach((ticket) => {
    //                     const productPrice = ticket.productPrice;
    //                     const qty = ticket.qty;

    //                     productPrices.push(productPrice);

    //                     if (ticket.repairCheckValue === "Purchase") {
    //                         calc_discount += productPrice * qty * (0 / 100);
    //                         totalAmount += productPrice * qty;
    //                     } else {
    //                         calc_discount += productPrice * qty * (100 / 100);
    //                         totalAmount += 0;
    //                     }
    //                 });
    //             }
    //         } else {
    //             overallDiscountedAmt += totalAmount;
    //         }

    //         const validDiscount = calc_discount || 0;
    //         let grandTotal = spareAmount > 0 ? spareAmount - validDiscount : validDiscount;
    //         grandTotal = Math.max(0, grandTotal);

    //         overallGrandTotal += grandTotal;
    //         overallSpareAmount += spareAmount;
    //     });

    //     return {
    //         overallSpareAmount,
    //         overallDiscountedAmt,
    //         overallGrandTotal,
    //     };
    // };




    let htmlContent;
    // Start HTML content with the header and styles

    htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="utf-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="description" content="">
          <meta name="author" content="">
          <title>ALIMCO</title>
          <style>
           body {
  margin: 0;
  padding: 0;
  font: 12pt "Tahoma";
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
  padding: 1cm;
  height: 256mm;
}

@page {
  size: A4 potrait;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
          </style>
        </head>
        <body>
    `;

    // Loop through rowData to generate content for each row
    rowData?.forEach((row, index) => {
        const total = row?.totalAmount || 0;
        const gstAmount = row?.gstAmount || 0;
        // Calculate the sum
        const totalWithGst = total + gstAmount || 0;
        // console.log(totalWithGst,gstAmount,total,"ppp");

        const formattedOutput = `${totalWithGst.toFixed(2)}`; // This will format to 2 decimal places

        // console.log("RRRRR", row);
        // const fixedPrice =
        //   Array.isArray(row?.ticketDetail) && row.ticketDetail.length > 0
        //     ? row.ticketDetail[0]?.repairPrice || 0
        //     : 0;

        // console.log(row, fixedPrice, "ppppp");

        // const totalAmount = Array.isArray(row?.ticketDetail)
        //   ? row.ticketDetail.reduce((total, item) => {
        //       const itemTotal = item.qty * item.serviceCharge;
        //       return total + itemTotal;
        //     }, 0)
        //   : 0; //repair amount or labour charges
        // console.log("total amt", totalAmount);

        // const spareAmount = Array.isArray(row?.ticketDetail)
        //   ? row.ticketDetail.reduce(
        //       (total, item) => total + item.qty * item.productPrice,
        //       0
        //     )
        //   : 0;

        const toWords = new ToWords();
        const words = toWords.convert(formattedOutput, {
            currency: true,
        });
        // console.log("print closed ticket", row);

        const ticketDetailsRows =
            Array.isArray(row?.ticketDetail) && row.ticketDetail.length
                ? row.ticketDetail
                    .map(
                        (item, index) => `
          <tr key="${index}" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${index + 1}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.categoryLabel || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.productLabel || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.repairLabel || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.repairCheckLabel || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.old_manufacture_name || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.old_serial_number || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.new_manufacture_name || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.new_serial_number || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.qty || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.productPrice || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">
                ${item.serviceCharge || "N/A"}
              </td>
              <td style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;text-align: center;">
                ${item.qty && item.productPrice
                                ? (item.qty * item.productPrice).toFixed(2)
                                : "N/A"
                            }
              </td>
          </tr>`
                    )
                    .join("")
                : `<tr style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
        <td colspan="13" style="border: 1px solid #afadad; text-align: center;">No data available</td>
     </tr>`;

        htmlContent += `

 <!DOCTYPE html>
      <html lang="en">
    <head>
  <meta charset="utf-8">
     <title> Artificial Limbs Manufacturing Corporation of India</title>
 </head>
 <body style="margin: 10px 0; font-size: 14px;line-height: 16px;">
 <div class="page">
<div class="subpage" style="background:url(${waterMarkJob}); background-size: contain; background-position: center; background-repeat: no-repeat;">    
 <table
         style="width: 740px!important; border: 1px solid black; margin: 0px auto; font-size: 12px; font-family:calibri"
        cellpadding="0" cellspacing="0">
         <tr>
             <td>
              <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                    <tr>
                         <td>
                             <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                                <tr>
                                    <td><strong style="margin-left: 5px;">GSTIN:</strong> 07AAHCK3696C1ZF</td>
                                    <td style="text-align: right;"><strong style="margin-right: 5px;">Original
                                            Copy</strong></td>
                                 </tr>
                             </table>
                         </td>
                     </tr>
                     <tr>
                         <td style="text-align: center;"><b>SALES INVOICE</b></td>
                     </tr>
                     <tr>
                         <td>
                             <table cellpadding="0" cellspacing="0" style="width: 100%; border-bottom: 1px solid black;">
                                 <tr>
                                     <td style="width: 32%; text-align: center;">
                                         <img src=${logo} alt="alimco-logo"
                                            width="120px">
                                   </td>
                                    <td>
                                         <span
                                             style="font-size: 22px; font-weight: bolder; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Artificial
                                             Limbs Manufacturing Corporation of India</span><br>
                                         <span><b
                                                 style="font-size:medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Office
                                                 Address</b></span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-size:small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Contact:</b>
                                             91-512-2770873, 2770687, 2770817</span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Email:
                                             </b>alimco@alimco.in</span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Drug
                                                 License Number: </b>20-UP32200002292 & 21-UP32210002287
                                             20B- UP3220B002488 & 21B- UP3221B002472
                                         </span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">FSSAI:
                                           </b>12720066000307</span><br><br>
                                     </td>
                                 </tr>
                             </table>
                         </td>
                     </tr>

                    <tr>
                         <td>
                             <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 1px solid black;">
                                <tr>
                                     <th
                                         style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: medium; width: 50%; padding: 5px;border-right: 1px solid #afadad;">
                                         Billing Address</th>
                                     <th
                                        style="font-size: medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; width: 50%;padding-left: 5px;">
                                         Shipping Address</th>
                                 </tr>
                            </table>
                         </td>
                     </tr>
                     <tr>
                         <td>
                             <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                 <tr>
                                    <td
                                         style="line-height: 20px; border-right: 1px solid black; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Address
                                                 :</b> ${row?.aasraName}
                                         </span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                                 Number :</b>${row?.gstNo || ""}
                                         </span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                 Serial Number# :</b>${row?.invoiceCode || ""
            }</span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                 Date :</b>
                                             ${row?.createdAt || ""}</span><br>

                                     </td>
                                    <td
                                         style="line-height: 20px; width: 50%; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                                 Number :</b>${row?.gstNo || ""
            }</span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                 Serial Number# :</b>${row?.invoiceCode || ""
            }
                                         </span><br>
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                 Date :</b>${row?.createdAt || ""
            }
                                         </span><br>

                                     </td>
                                 </tr>
                            </table>
                         </td>
                     </tr>
                     <tr>
                         <td>
                             <table cellpadding="2" cellspacing="0" style="width: 100%; border-bottom: 0px solid black;">
                                 <tr
                                     style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                     <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                         S.NO</th>
                                     <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Category</th>
                                     <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;width: 12%">
                                         Item</th>
                                     <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;width: 15%">
                                         Description of Goods</th>
                                   <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;width: 12%">
                                        Repairing and Handling</th>
                                    <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                         Old Manufacturer</th>
                                    <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                         Old Part Sr.No.</th>
                                          <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                         New Manufacturer</th>
                                          <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                         New Part Sr.No.</th>
                                         <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                         QTY</th>
                                     <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;width: 10%">
                                         Basic Price</th>
                                     <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                         Labour Charge</th>
                                     <th
                                         style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;width: 10%">
                                         Total</th>
                                 </tr>
     ${ticketDetailsRows}
                             </table>

                         </td>
                     </tr>
                     <tr>
                         <td>
                             <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                 <tr style="height:90px;">
                                     <td></td>
                                 </tr>
                             </table>
                         </td>
                     </tr>
                     <tr>
                         <td>
                             <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                 <tr>
                                     <td style="line-height: 20px; border-right: 1px solid black;padding-left: 5px; width: 50% !important;">
                                         <span></span><br>
                                         <span></span><br>
                                         <span></span><br>
                                    
                                         <span
                                             style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Total
                                                 Invoice Amount in Words:
                                                </b>
                                                 <span style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;" >${words}
                                    
                                                 </span>
                                     </td>
                                     <td style="line-height: 20px; width: 15%; text-align: end;padding-left: 5px;">
                                         <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Sub
                                             Total:</span><br>
                                         <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Repair
                                            Amount:</span><br>
                                              <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                             GST Amount:</span><br>
                                             <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                             Discount:</span><br>
                                                <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                             Additional Discount:</span><br>
                                         <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Grand
                                             Total:</span>
                                     </td>
                                      <td
                                        style=";padding-top:10px;line-height: 20px; width: 15%; text-align: right; vertical-align: text-top; font-size: small; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;padding-right: 5px;">

                                          <span>${RUPEES_SYMBOL} ${row?.total.toFixed(2) || 0.00
            }</span><br>
                                        <span>${RUPEES_SYMBOL}${parseFloat(row?.subtotal).toFixed(2) || 0.00} </span><br>                       
     <span>${RUPEES_SYMBOL} ${row?.gstAmount.toFixed(2) || 0.00}</span><br>
     <span>${RUPEES_SYMBOL} ${row?.discount || 0.00
            }</span><br>     
    
    <span>${RUPEES_SYMBOL} ${row?.additionalDiscount || 0.00
            }</span><br>    
                                        
                                        <span>${RUPEES_SYMBOL} ${formattedOutput || 0.00}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                <td style="border-right: 1px solid black;padding-left: 5px;">
                                    <h3
                                        style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bolder; margin: 0; padding: 0;">
                                        TERMS & CONDITIONS</h3>
                                    <ol
                                        style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: small;">
                                        <li>Goods Once dispatch will not be taken Back.</li>
                                        <li>Subject to Lucknow Jurisdiction only.</li>
                                    </ol>
                                    </span>
                                </td>
                                <td style="padding: 5px;">
                                   
                                    <div style="display: flex; justify-content:space-between; font-size: small;">
                                        <p
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; padding: 0; margin: 40px 0px 0px 0px;">
                                            <b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Employee
                                                ID: </b>${row?.uniquiCode || ""}
                                        </p>
                                        <p
                                            style="padding: 0; margin: 40px 0px 0px 0px; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                                            Authorised Signatory</p>
                                    </div>
                                </td>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                <p style="font-size: small; font-weight:bold; padding: 0; margin: 0px 0px 0px 10px;">
                                    Declaration : <br>
                                    We Declare that this Invoice shows the actual price of the Goods described and that
                                    all
                                    particulars are true and correct. <br>
                                    Registered Office- ALIMCO, G. T. Road, Kanpur, Uttar Pradesh - 209217 <br>
                                    CIN -U01100MH1999PLC120563 PAN- AABCR1718E Website- www. Artificial Limbs
                                    Manufacturing Corporation of India.com Contact No
                                    -+91 9648484859 <br> Email- alimco@alimco.in | Artificial Limbs Manufacturing
                                    Corporation of India.co</p>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    </div>
    </div>
     ${index < rowData.length - 1 ? '<div class="page-break"></div>' : ""}
      </body>
      </html>

        `;
    });

    // Close HTML content
    htmlContent += `
        </body>
      </html>
    `;

    // Open a new window and print the document
    const printWindow = window.open();

    if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(htmlContent);
        // printWindow.document.close();
        // printWindow.onload = async () => {
        //     try {
        //         // Use html2canvas to capture the content as an image
        //         const canvas = await html2canvas(printWindow.document.body, {
        //             scale: 2, // Adjust scale as needed for better resolution
        //             useCORS: true, // Ensure that cross-origin images are handled
        //         });

        //         // Get the data URL of the canvas (image)
        //         const imageData = canvas.toDataURL("image/png");

        //         // Open the image in a new tab for demonstration (optional)
        //         const newTab = window.open();
        //         newTab.document.write('<img src="' + imageData + '"/>');

        //         // Trigger the print dialog after a short delay
        //         setTimeout(() => {
        //             printWindow.print();
        //         }, 3000); // Adjust delay if necessary

        //     } catch (error) {
        //         console.error("Error capturing content with html2canvas:", error);
        //     }
        // };
        // printWindow.print();
        // Trigger the print dialog
        printWindow.document.close(); // necessary for some browsers to finish writing before printing
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 2000)
    } else {
        alert(
            "Failed to open print window. Please check your browser's pop-up settings."
        );
    }
};

export const handleClosedTicketPrint = (row) => {
    const fixedPrice = row?.ticketDetail[0]?.repairPrice || 0;
    //   console.log(row, fixedPrice, "ppppp");
    let calc_discount = 0;
    let discountedAmt = 0;
    let totalAmount = 0; // Initialize to calculate total cost
    let productPrices = []; // Array to store productPrice values

    // Calculate the spare amount based on qty * productPrice for all items
    const spareAmount = row?.ticketDetail.reduce((total, item) => {
        // If it's a repair, the value is 0 (100% discount)
        return (
            total +
            (item.repairCheckValue === "Repair" ? 0 : item.qty * item.productPrice)
        );
    }, 0);

    //   const totalAmount = row?.ticketDetail.reduce((total, item) => {
    //     const itemTotal = item.qty * item.serviceCharge;
    //     return total + itemTotal;
    //   }, 0);
    //   const spareAmount = row?.ticketDetail.reduce(
    //     (total, item) => total + item.qty * item.productPrice,
    //     0
    //   );

    //   console.log("print closed ticket", row);
    const ticketDetailsRows = row?.ticketDetail
        ?.map(
            (item, index) => `
    <tr key="${index}"  style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${index + 1
                }</td>
      

        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.categoryLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.productLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.repairLabel
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.repairCheckLabel
                }</td>
         <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.old_manufacture_name || "N/A"
                }</td>
         <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.old_serial_number || "N/A"
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.new_manufacture_name || "N/A"
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.new_serial_number || "N/A"
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.qty
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.productPrice
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.serviceCharge
                }</td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${(
                    item.qty * item.productPrice
                )?.toFixed(2)}</td>
    </tr>
`
        )
        .join("");

    //   const totalAmount = row?.ticketDetail.reduce(
    //     (total, item) => total + (item.qty * item.price + item.serviceCharge),
    //     0
    //   );

    let totalLabourAmount = row?.ticketDetail.reduce((total, item) => {
        const fixedCharge = fixedPrice;
        const itemTotal = total + item.qty * item.serviceCharge;
        // console.log("5555", itemTotal, total, fixedCharge);
        // setCostDifference(itemTotal)
        return itemTotal;
    }, 0);

    //   console.log(totalLabourAmount, "ttttt");
    if (row?.warranty === true) {
        if (row?.ticketDetail.length > 0) {
            row?.ticketDetail.forEach((ticket) => {
                const productPrice = ticket.productPrice;
                const qty = ticket.qty;

                productPrices.push(productPrice); // Add product price to the array

                // Check the condition for repair value
                if (ticket.repairCheckValue === "Purchase") {
                    // No discount for purchases
                    calc_discount += productPrice * qty * (0 / 100); // No discount
                    totalAmount += productPrice * qty; // Include the product price with the quantity
                } else {
                    // Apply 100% discount for repairs
                    calc_discount += productPrice * qty * (100 / 100); // 100% discount for repairs
                    totalAmount += 0; // Total amount for repairs should be zero
                }
            });
        }

        // console.log("All Product Prices:", productPrices); // This will log the array with all product prices
        // console.log("Total Discount Amount", calc_discount);

        // discountedAmt = totalAmount - calc_discount; // Calculate the final amount after discount
        // discountedAmt = Math.max(0, discountedAmt);
        // console.log("Total payable Amount", discountedAmt);
    } else {
        // If out of warranty, no discount for any of them
        // console.log("No discount applied, warranty is false.");
        discountedAmt = totalAmount || 0; // No discount applied
        // console.log("Discounted Amount without discount", discountedAmt);
    }

    //   console.log("Spare Amount:", spareAmount);

    // Ensure `discountedAmt` is valid before calculating `grandTotal`
    // Ensure calc_discount has a valid value
    const validDiscount = calc_discount || 0;

    // Check if spareAmount has a valid value (greater than 0)
    let grandTotal = 0;
    if (spareAmount > 0) {
        grandTotal = spareAmount - validDiscount;
    } else {
        grandTotal = validDiscount;
    }

    // Ensure the grandTotal is not negative
    grandTotal = Math.max(0, grandTotal);

    //   console.log("Grand Total:", grandTotal);

    const total = row?.totalAmount || 0; // Default to 0 if undefined or null
    const gstAmount = row?.gstAmount || 0; // Default to 0 if undefined or null

    // Calculate the sum
    const totalWithGst = total + gstAmount;

    const formattedOutput = `${totalWithGst.toFixed(2)}`; // This will format to 2 decimal places
    const toWords = new ToWords();
    const words = toWords.convert(formattedOutput, { currency: true });

    const printWindow = window.open();
    printWindow.document.write(
        `
      <!DOCTYPE html>
      <html lang="en">
     <head>
    <meta charset="utf-8">
    <title> Artificial Limbs Manufacturing Corporation of India</title>
        <style>
        @media print {
            button {
                display: none;
            }
            @page {
                size: A4 portrait;
                margin:default;
                font-size:14px;
            }
        }
        .page {
            width: 21.3cm;
            min-height: auto;
            margin: 1cm auto;
            border: 1px #D3D3D3 solid;
            border-radius: 0px;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
        }
     
    </style>
</head>
<body >
  <div class="page">
        <div class="subpage" style="background:url(${waterMarkJob});background-size: cover;
        background-position: center;">
            <div style="padding: 2px; height: -webkit-fill-available;">
          <table
        style="width: 800px; border: 1px solid black; margin: 0px auto; font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif"
        cellpadding="0" cellspacing="0">
        <tr>
            <td>
                <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                                <tr>
                                    <td><strong style="margin-left: 5px;">GSTIN:</strong> 07AAHCK3696C1ZF</td>
                                    <td style="text-align: right;"><strong style="margin-right: 5px;">Original
                                            Copy</strong></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;"><b>SALES INVOICE</b></td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%; border-bottom: 1px solid black;">
                                <tr>
                                    <td style="width: 32%; text-align: center;">
                                        <img src=${logo} alt="alimco-logo"
                                            width="120px">
                                    </td>
                                    <td>
                                        <span
                                            style="font-size: 22px; font-weight: bolder; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Artificial
                                            Limbs Manufacturing Corporation of India</span><br>
                                        <span><b
                                                style="font-size:medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Office
                                                Address</b></span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size:small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Contact:</b>
                                            91-512-2770873, 2770687, 2770817</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Email:
                                            </b>alimco@alimco.in</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Drug
                                                License Number: </b>20-UP32200002292 & 21-UP32210002287
                                            20B- UP3220B002488 & 21B- UP3221B002472
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">FSSAI:
                                            </b>12720066000307</span><br><br>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 1px solid black;">
                                <tr>
                                    <th
                                        style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: medium; width: 50%; padding: 5px;border-right: 1px solid #afadad;">
                                        Billing Address</th>
                                    <th
                                        style="font-size: medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; width: 50%;padding-left: 5px;">
                                        Shipping Address</th>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                <tr>
                                    <td
                                        style="line-height: 20px; border-right: 1px solid black; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Address
                                                :</b> ${row?.aasraName}
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                                Number :</b>${row?.gstNo || ""}
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                Serial Number# :</b>${row?.invoiceCode || ""
        }</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                Date :</b>
                                            ${row?.createdAt || ""}</span><br>

                                    </td>
                                    <td
                                        style="line-height: 20px; width: 50%; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                                Number :</b>${row?.gstNo || ""
        }</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                Serial Number# :</b>${row?.invoiceCode || ""
        }
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                Date :</b>${row?.createdAt || ""
        }
                                        </span><br>

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="2" cellspacing="0" style="width: 100%; border-bottom: 0px solid black;">
                                <tr
                                    style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        S.NO</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Category</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Item</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Description of Goods</th>
                                   <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Repairing and Handling</th>
                                   <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Old Manufacturer</th>
                                   <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Old Part Sr.No.</th>
                                         <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        New Manufacturer</th>
                                         <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        New Part Sr.No.</th>
                                        <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        QTY</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Basic Price</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Labour Charge</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;">
                                        Total</th>

                                </tr>

    ${ticketDetailsRows}
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                <tr style="height: 200px;">
                                    <td></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                     <tr>
                         <td>
                             <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                 <tr>
                                     <td style="line-height: 20px;padding-top:5px; border-right: 1px solid black;padding-left: 5px; ">
                                         <span></span><br>
                                         <span></span><br>
                                         <span></span><br>
                                    
                                         <span
                                             style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;"><b
                                                 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Total
                                                 Invoice Amount in Words:
                                                </b>
                                                 <span style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;" >${words}</span>
                                     </td>
                                     <td style="line-height: 20px;padding-top:5px; width: 18%; text-align: end;padding-left: 5px;">
                                         <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Sub
                                             Total:</span><br>
                                         <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Repair
                                            Amount:</span><br>
                                              <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                             GST Amount:</span><br>
                                             <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                             Discount:</span><br>
                                              <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                             Additional Discount:</span><br>
                                         <span
                                             style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Grand
                                             Total:</span>
                                     </td>
                                      <td
                                        style="padding-top:5px;line-height: 20px; width: 15%; text-align: right; vertical-align: text-top; font-size: small; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;padding-right: 5px;">

                                          <span>${RUPEES_SYMBOL} ${row?.total.toFixed(2) || 0
        }</span><br>
                                        <span>${RUPEES_SYMBOL} ${row?.subtotal}</span><br>                       
     <span>${RUPEES_SYMBOL} ${row?.gstAmount.toFixed(2) || 0}</span><br>
     <span>${RUPEES_SYMBOL} ${row?.discount || 0
        }</span><br>      
    <span>${RUPEES_SYMBOL} ${row?.additionalDiscount || 0
        }</span><br>                                  
                                        
                                        <span>${RUPEES_SYMBOL} ${formattedOutput || 0}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                <td style="border-right: 1px solid black;padding-left: 5px;">
                                    <h3
                                        style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bolder; margin: 0; padding: 0;">
                                        TERMS & CONDITIONS</h3>
                                    <ol
                                        style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: small;">
                                        <li>Goods Once dispatch will not be taken Back.</li>
                                        <li>Subject to Lucknow Jurisdiction only.</li>
                                    </ol>
                                    </span>
                                </td>
                                <td style="padding: 5px;">
                                   
                                    <div style="display: flex; justify-content:space-between; font-size: small;">
                                        <p
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; padding: 0; margin: 40px 0px 0px 0px;">
                                            <b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Employee
                                                ID: </b>${row?.uniquiCode || ""}
                                        </p>
                                        <p
                                            style="padding: 0; margin: 40px 0px 0px 0px; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                                            Authorised Signatory</p>
                                    </div>
                                </td>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                <p style="font-size: small; font-weight:bold; padding: 0; margin: 0px 0px 0px 10px;">
                                    Declaration : <br>
                                    We Declare that this Invoice shows the actual price of the Goods described and that
                                    all
                                    particulars are true and correct. <br>
                                    Registered Office- ALIMCO, G. T. Road, Kanpur, Uttar Pradesh - 209217 <br>
                                    CIN -U01100MH1999PLC120563 PAN- AABCR1718E Website- www. Artificial Limbs
                                    Manufacturing Corporation of India.com Contact No
                                    -+91 9648484859 <br> Email- alimco@alimco.in | Artificial Limbs Manufacturing
                                    Corporation of India.co</p>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
      </div>
        </div>
    </div>
      </body>
      </html>
    `
    );
    // printWindow.focus();
    // printWindow.print()
    printWindow.document.close(); // necessary for some browsers to finish writing before printing
    setTimeout(() => {
        printWindow.print();
        printWindow.close();
    }, 2000)
};

export const printPO = (row, aarsaData, paymentData, total, gst, data) => {
    //   console.log(data, row, paymentData, aarsaData, "jjghgfhg");
    const toWords = new ToWords();
    const a = parseFloat(data?.gst_amount || 0).toFixed(2) || 0;
    const b = parseFloat(data?.grand_total || 0).toFixed(2) || 0;
    const totalGstPrice = (parseFloat(a) + parseFloat(b)).toFixed(2) || 0;

    const words = toWords.convert(totalGstPrice || 0, { currency: true });
    const ticketDetailsRows = row
        ?.map(
            (item, index) => `
    <tr key="${index}"  style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 30px;font-size: 13px;">
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${index + 1
                }</td>
      
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.item_name
                }</td>
       
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.quantity
                }
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${RUPEES_SYMBOL}&nbsp;${item.price
                }</td>   
                </td>
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;text-align: center;">${item.gst || 0
                }</td>   
        <td  style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;text-align: center;">${RUPEES_SYMBOL}&nbsp;${parseFloat(
                    item.quantity * item.price
                ).toFixed(2)}</td>
    </tr>
`
        )
        .join("");

    const totalAmount = row?.reduce(
        (total, item) => total + item.quantity * item.price,
        0
    );

    const printWindow = window.open();
    printWindow.document.write(
        `
        <!DOCTYPE html>
      <html lang="en">
     <head>
    <meta charset="utf-8">
    <title> Artificial Limbs Manufacturing Corporation of India</title>
    <style>
        @media print {
            button {
                display: none;
            }

            @page {
                size: A4 portrait;
                margin: default ;
            }
        }
        .page {
            width: 19.4cm;
            min-height: auto;
            margin: 1cm auto;
            border: 1px #D3D3D3 solid;
            border-radius: 0px;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
        }

         .subpage {
            width: 19.4cm;
            /* border: 5px solid #2f5e79; */
            height: 275mm;
            box-sizing: border-box;
        }


        @page {
            size: A4;
        }

        @media print {
            .page {
                margin: 0;
                border: initial;
                border-radius: initial;
                width: initial;
                min-height: initial;
                box-shadow: initial;
                background: initial;
            }
        }
    </style>
</head>
<body>
    <div class="page">
        <div class="subpage" style="background:url(${waterMarkJob});background-size: cover;
        background-position: center;">
            <div style="padding: 2px; height: -webkit-fill-available;">
          <table
        style="border:  solid 1px #000; margin: 0px auto; font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif"
        cellpadding="0" cellspacing="0">
        <tr>
            <td>
                <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                                <tr>
                                    <td><strong style="margin-left: 5px;">GSTIN:</strong> 07AAHCK3696C1ZF</td>
                                    <td style="text-align: right;"><strong style="margin-right: 5px;">Original
                                            Copy</strong></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;"><b>SALES INVOICE</b></td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%; border-bottom: 1px solid black;">
                                <tr>
                                    <td style="width: 32%; text-align: center;">
                                        <img src=${logo} alt="alimco-logo"
                                            width="120px">
                                    </td>
                                    <td>
                                        <span
                                            style="font-size: 22px; font-weight: bolder; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Artificial
                                            Limbs Manufacturing Corporation of India</span><br>
                                        <span><b
                                                style="font-size:medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Office
                                                Address</b></span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size:small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Contact:</b>
                                            91-512-2770873, 2770687, 2770817</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Email:
                                            </b>alimco@alimco.in</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Drug
                                                License Number: </b>20-UP32200002292 & 21-UP32210002287
                                            20B- UP3220B002488 & 21B- UP3221B002472
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">FSSAI:
                                            </b>12720066000307</span><br><br>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 1px solid black;">
                                <tr>
                                    <th
                                        style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: medium; width: 50%; padding: 5px;border-right: 1px solid #afadad;">
                                        Aasra Address</th>
                                    <th
                                        style="font-size: medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; width: 50%;padding-left: 5px;">
                                        Shipping Address</th>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                <tr>
                                    <td
                                        style="line-height: 20px; border-right: 1px solid black; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Address
                                                :</b> ${aarsaData?.address}
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                                Number :</b>${data?.gstNo || ""}
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                Serial Number# :</b>${paymentData?.invoice_number
        }</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                Date :</b>
                                           ${paymentData?.createdAt}</span><br>

                                    </td>
                                    <td
                                        style="line-height: 20px; width: 50%; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                         <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Address
                                                :</b> ${aarsaData?.address}
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                                Number :</b>${data?.gstNo || ""
        }</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                                Serial Number# :</b>${paymentData?.invoice_number
        }
                                        </span><br>

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="2" cellspacing="0" style="width: 100%; border-bottom: 0px solid black;">
                                <tr
                                    style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        S.NO</th>
                                 
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Item</th>
                             
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        QTY</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        Basic Price</th>
                                     <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                        GST</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;">
                                        Total</th>

                                </tr>

    ${ticketDetailsRows}
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                <tr style="height: 200px;">
                                    <td></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                <tr>
                                    <td style="line-height: 20px; border-right: 1px solid black;padding-left: 5px;vertical-align: top;padding-top: 5px;">
                                       <span
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                               Transaction Id:</b>  
                                   <span style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;" >${data?.transaction_id || ""
        }</span>
</br>
                                        <span
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Total
                                                Invoice Amount in Words:</b>
                                                <span style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;" >${words}</span>
                                    </td>
                                    <td style="line-height: 20px; width: 15%; text-align: end;padding-left: 5px;">
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Sub
                                            Total:</span><br>
                                       ${data?.sgst || data?.cgst
            ? `
                                         <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">SGST:
                                            </span>
                                            <br/>
                                         <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">CGST:
                                            </span>
                                            <br/>
                                       `
            : `
                                             <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">GST:
                                            </span>
                                            <br/>
                                           `
        }
                                           
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Shipping Charges:</span>  <br>
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Discount:</span>  <br>
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Grand
                                            Total:</span>
                                    </td>
                                    <td style="line-height: 20px; width: 15%; text-align: end;padding-left: 5px;padding-right:5px;">
                                        <span
                                            style="font-size: small;"> <span>${RUPEES_SYMBOL}&nbsp;${parseFloat(data?.total_bill).toFixed(2) || 0.0
        } </span><br>
                                       
                                      
                                        ${data?.sgst || data?.cgst
            ? ` 
                                         <span
                                            style="font-size: small;">${data?.sgst || 0.0
            }%
                                            </span>
                                            <br/>
                                         <span
                                            style="font-size: small;">${data?.cgst || 0.0
            }%
                                            </span>
                                            <br/>
                                      `
            : `
                                              <span
                                            style="font-size: small;">${parseFloat(data?.gst_amount || 0).toFixed(2)
            }
                                            </span>
                                            <br/>
                                            `
        }
                                       
                                        <span
                                            style="font-size: small;"><span>${RUPEES_SYMBOL}&nbsp;${parseFloat(
            data?.shipping_charges || 0.0
        ).toFixed(2)} </span>  <br>
                                        <span
                                            style="font-size: small;"><span>${RUPEES_SYMBOL}&nbsp;${parseFloat(
            data?.discount || 0.0
        ).toFixed(2)} </span>  <br>
                                        <span
                                            style="font-size: small;"><span>${RUPEES_SYMBOL}&nbsp;${parseFloat(
            totalGstPrice || 0
        ).toFixed(2)} </span>
                                    </td>
                                    
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black;">
                                <td style="border-right: 1px solid black;padding-left: 5px;">
                                    <h3
                                        style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bolder; margin: 0; padding: 0;">
                                        TERMS & CONDITIONS</h3>
                                    <ol
                                        style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: small;">
                                        <li>Goods Once dispatch will not be taken Back.</li>
                                        <li>Subject to Lucknow Jurisdiction only.</li>
                                    </ol>
                                    </span>
                                </td>
                                <td style="padding: 5px;">
                                   
                                    <div style="display: flex; justify-content:space-between; font-size: small;">
                                        <p
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; padding: 0; margin: 40px 0px 0px 0px;">
                                            <b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Employee
                                                ID: </b>364589
                                        </p>
                                        <p
                                            style="padding: 0; margin: 40px 0px 0px 0px; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                                            Authorised Signatory</p>
                                    </div>
                                </td>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                <p style="font-size: small; font-weight:bold; padding: 0; margin: 10px 0px 30px 10px;">
                                    Declaration : <br>
                                    We Declare that this Invoice shows the actual price of the Goods described and that
                                    all
                                    particulars are true and correct. <br>
                                    Registered Office- ALIMCO, G. T. Road, Kanpur, Uttar Pradesh - 209217 <br>
                                    CIN -U01100MH1999PLC120563 PAN- AABCR1718E Website- www. Artificial Limbs
                                    Manufacturing Corporation of India.com Contact No
                                    -+91 9648484859 <br> Email- alimco@alimco.in | Artificial Limbs Manufacturing
                                    Corporation of India.co</p>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
       </div>
      </div>
    </div>
      </body>
      </html> 
    `
    );
    // printWindow.print();
    // printWindow.close();
    printWindow.document.close(); // necessary for some browsers to finish writing before printing
    setTimeout(() => {
        printWindow.print();
        printWindow.close();
    }, 100)
};

export const downloaadPdf = async (
    row,
    orderData,
    aarsaData,
    paymentData,
    total,
    sgst,
    cgst,
    gst,
    total_bill,
    total_tax,
    shipping_charges,
    discount
) => {

    const totalGstPricePartial = parseFloat(row?.grand_total_gst_partial).toFixed(2);
    const creditNoteTotal = parseFloat(row?.creditnotetotal || 0).toFixed(2);
    const totalPartial = (parseFloat(totalGstPricePartial) + parseFloat(creditNoteTotal)).toFixed(2);
    const totalGstPrice = parseFloat(row?.grand_total_gst).toFixed(2);

    //   console.log(totalGstPricePartial);  
    //   console.log(creditNoteTotal);  
    //   console.log(totalGstPrice); 
    //   console.log(totalPartial); 
    const toWords = new ToWords();
    const words = toWords.convert(totalGstPrice || 0.0, { currency: true });
    const ticketDetailsRows = orderData
        ?.map(
            (item, index) => `
    <tr key="${index}" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">
        <td style="border-top: 0px solid #afadad; border-bottom: 1px solid #afadad; border-left: 0px solid #afadad; border-right: 1px solid #afadad; text-align: center;">${index + 1
                }</td>
        <td style="border-top: 0px solid #afadad; border-bottom: 1px solid #afadad; border-left: 0px solid #afadad; border-right: 1px solid #afadad; text-align: center;">${item.item_name
                }</td>
        <td style="border-top: 0px solid #afadad; border-bottom: 1px solid #afadad; border-left: 0px solid #afadad; border-right: 1px solid #afadad; text-align: center;">${item.quantity
                }</td>
        <td style="border-top: 0px solid #afadad; border-bottom: 1px solid #afadad; border-left: 0px solid #afadad; border-right: 1px solid #afadad; text-align: center;">Rs.${" "}${item.price
                }</td>
            </td>
        <td style="border-top: 0px solid #afadad; border-bottom: 1px solid #afadad; border-left: 0px solid #afadad; border-right: 1px solid #afadad; text-align: center;">${item.gstpercent.gst || 0
                }</td>
        <td style="border-top: 0px solid #afadad; border-bottom: 1px solid #afadad; border-left: 0px solid #afadad; border-right: 0px solid #afadad; text-align: center;">
        Rs.${" "}${parseFloat(item.quantity * item.itemUnitPrice).toFixed(2)
                }</td>


    </tr>
`
        )
        .join("");

    const totalAmount = orderData?.reduce(
        (total, item) => total + item.quantity * item.price,
        0
    );

    const doc = new jsPDF({
        format: "a4",
        unit: "px",
    });

    // Use html2canvas to capture the HTML content
    const htmlContent = `
    <!DOCTYPE html>
  <html lang="en">
 <head>
<meta charset="utf-8">
<title> Artificial Limbs Manufacturing Corporation of India</title>
</head>
<body style="margin: 10px 0; font-size: 14px;line-height: 14px;white-space: normal;">
          <div class="subpage" style="background:url(${waterMarkJob});
     background-size: contain;
    background-position: center;
    background-repeat: no-repeat;">
      <table
    style="width: 800px; border: 0.8px solid black; margin: 0px auto; font-size: 12px; white-space: normal; font-family:Verdana, Geneva, Tahoma, sans-serif"
    cellpadding="0" cellspacing="0">
    <tr>
        <td>
            <table cellpadding="0" cellspacing="0" style="width: 100%; border: 0px solid black;">
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                            <tr>
                                <td><strong style="margin-left: 5px;">GSTIN:</strong> 07AAHCK3696C1ZF</td>
                                <td style="text-align: right;"><strong style="margin-right: 5px;">Original
                                        Copy</strong></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="text-align: center;"><b>SALES INVOICE</b></td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" style="width: 100%; white-space: normal; border-bottom: 1px solid black;letter-spacing: 0.5px">
                            <tr>
                                <td style="width: 32%; text-align: center;">
                                    <img src=${logo} alt="alimco-logo"
                                        width="120px">
                                </td>
                                <td>
                                    <span
                                        style="font-size: 22px; font-weight: bolder; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;letter-spacing: 0.5px">Artificial
                                        Limbs Manufacturing Corporation of India</span><br>
                                    <span><b
                                            style="font-size:medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Office
                                            Address</b></span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-size:small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Contact:</b>
                                        91-512-2770873, 2770687, 2770817</span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Email:
                                        </b>alimco@alimco.in</span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Drug
                                            License Number: </b>20-UP32200002292 & 21-UP32210002287
                                        20B- UP3220B002488 & 21B- UP3221B002472
                                    </span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">FSSAI:
                                        </b>12720066000307</span><br><br>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table cellspacing="0" cellpadding="0"
                            style="width: 100%; text-align: left; border-bottom: 1px solid black;letter-spacing: 0.5px">
                            <tr>
                                <th
                                    style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: medium; width: 50%; padding: 5px;border-right: 1px solid #afadad;">
                                    Aasra Address</th>
                                <th
                                    style="font-size: medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; width: 50%;padding-left: 5px;">
                                    Shipping Address</th>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" style="width: 100%;letter-spacing: 0.5px">
                            <tr>
                                <td
                                    style="line-height: 20px; border-right: 1px solid black; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Address
                                            :</b> ${aarsaData?.address}
                                    </span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                            Number :</b>${row?.gstNo || ""}
                                    </span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                            Serial Number# :</b>${paymentData?.invoice_number
        }</span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                            Date :</b>
                                        ${paymentData?.createdAt}</span><br>

                                </td>
                                <td
                                    style="line-height: 20px; width: 50%; padding: 5px 0px 0px 5px;border-bottom: 1px solid #afadad;">
                                     <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Address
                                            :</b> ${aarsaData?.address}
                                    </span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GST
                                            Number :</b>${row?.gstNo || ""
        }</span><br>
                                    <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Invoice
                                            Serial Number# :</b>${paymentData?.invoice_number
        }
                                    </span><br>

                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="2" cellspacing="0" style="width: 100%; border-bottom: 0px solid black;letter-spacing: 0.5px">
                            <tr
                                style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                <th
                                    style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                    S.NO</th>
                             
                                <th
                                    style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                    Item</th>
                         
                                <th
                                    style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                    QTY</th>
                                <th
                                    style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                    Basic Price</th>
                                <th
                                    style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 1px solid #afadad;">
                                   GST %</th>
                               
                                <th
                                    style="border-top: 0px solid #afadad;border-bottom: 1px solid #afadad;border-left: 0px solid #afadad;border-right: 0px solid #afadad;">
                                    Total</th>

                            </tr>

${ticketDetailsRows}
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black; white-space: normal;">
                            <tr style="height: 200px;">
                                <td></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black; white-space: normal;letter-spacing: 0.5px">
                            <tr>
                                <td style="line-height: 20px; border-right: 1px solid black;padding-left: 5px;vertical-align: top;padding-top: 5px;"> 
                                        <span
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                               Transaction Id:</b>  
                                   <span style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">${row?.transaction_id || ""
        }</span>
</br>
                                        <span
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;font-size: small;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Total
                                                Invoice Amount in Words:</b>
                                                <span style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">${words}</span>
                                        
                                        <br>
                                    </td>
                                <td style="line-height: 20px; width: 15%; text-align: end;padding-left: 5px; white-space: normal;">
                                    ${sgst > 0 || cgst > 0
            ? `
                                         <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">SGST:
                                            </span>
                                            <br/>
                                         <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">CGST:
                                            </span>
                                      `
            : `
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">GST Amount:
                                            </span>
                                            <br>
                                        `
        }
                                    <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Shipping Charges:</span>  <br>
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Discount:</span>  <br>
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Grand
                                            Total:</span>
                                </td>
                                <td
                                   style="line-height: 20px; width: 15%; text-align: end;padding-left: 5px;padding-right:5px;">                                                                         
                                     ${sgst > 0 || cgst > 0
            ? ` 
                                         <span
                                            style="font-size: small; ">${sgst || 0
            }%
                                            </span>
                                            <br/>
                                         <span
                                            style="font-size: small;">${cgst || 0
            }%
                                            </span>
                                      `
            : `<span
                                            style="font-size: small;">Rs.${" "}${parseFloat(
                row?.gst_price || 0.0
            ).toFixed(2)}

                                            </span> <br>`
        }
                                   <span>Rs. ${parseFloat(shipping_charges || 0.0).toFixed(2)}</span><br>
                                    <span>Rs.${" "}${parseFloat(
            discount || 0.0
        ).toFixed(2)}</span><br>
  
                                    <span>Rs.${" "}${parseFloat(
            totalGstPrice || 0.0
        ).toFixed(2)}</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 1px solid black; white-space: normal;">
                            <td style="border-right: 1px solid black;padding-left: 5px;">
                                <h3
                                    style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bolder; margin: 0; padding: 0;letter-spacing: 1px">
                                    TERMS & CONDITIONS</h3>
                                <span
                                    style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;letter-spacing: 1px">
                                    <span>1.Goods Once dispatch will not be taken Back.</span></br>
                                    <span>2.Subject to Lucknow Jurisdiction only.</span>
                               
                                </span>
                            </td>
                            <td style="padding: 5px;">
                               
                                <div style="display: flex; justify-content:space-between; font-size: small;">
                                    <p
                                        style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; padding: 0; margin: 40px 0px 0px 0px;">
                                        <b
                                            style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Employee
                                            ID: </b>364589
                                    </p>
                                    <p
                                        style="padding: 0;margin: 40px 0px 0px 0px; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small; letter-spacing: 1px">
                                        Authorised Signature</p>
                                </div>
                            </td>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="margin:0px 50px 0px 5px">
                        <table cellpadding="0" cellspacing="0" style="width: 100%;border:0px ">
                        <tr >
                        <td >
                          <span style="font-size: small;font-weight:450; padding:5px;">
                                Declaration : <br></span>
                         <span style="font-size: small; padding:5px; ">
                                We Declare that this Invoice shows the actual price of the Goods described and that all particulars are true and correct.</span><br>
                                  <span style="font-size: small; padding:5px;">
                                Registered Office- ALIMCO, G.T. Road,Kanpur,Uttar Pradesh - 209217 </span><br>
                                  <span style="font-size: small; padding:5px;">
                         CIN -U01100MH1999PLC120563 PAN-AABCR1718E</span><br>
                                  <span style="font-size: small; padding:5px;">
                               Website- www.alimco.in Artificial Limbs Manufacturing Corporation of India.com Contact No- +91 9648484859 </span> <br>
                                  <span style="font-size: small; padding:5px;">
                                 Email- alimco@alimco.in | Artificial Limbs Manufacturing Corporation of India.co
                                </span>
                              
                        </td>
                        </tr>
                           
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
</div>
  </body>
  </html> 
`;


    // Open a new window to render the content
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();

    printWindow.onload = async () => {
        const doc = new jsPDF('p', 'pt', 'a4');
        const invoiceNumber = paymentData?.invoice_number || '';
        const orderDate = row?.order_date || '';
        const underWarranty = row?.underWarranty || '';
        const fileName = invoiceNumber.length > 1
            ? invoiceNumber
            : `${orderDate}_${underWarranty}`.replace(/[\/?*<>|":]/g, "_"); // Sanitize file name
      
        // Replace HTML entity with the Rupee symbol
         document.querySelectorAll('span').forEach((span) => {
        if (span.innerHTML.includes("&#8377;")) {
            span.innerHTML = span.innerHTML.replace(/&#8377;/g, '₹');
        }
    });
        // Get the updated HTML content from the print window
        const htmlContent = printWindow.document.body.innerHTML;

        // Generate the PDF
        await doc.html(htmlContent, {
            callback: (pdf) => {
                pdf.save(`${fileName}.pdf`);
                printWindow.close();
            },
            margin: [10, 10, 10, 10], // top, left, bottom, right
            html2canvas: {
                scale: 0.7, // Adjust to fit content within page
                letterRendering: true,
                useCORS: true, // If your HTML content has images from other domains
            },
            x: 10,
            y: 10,
        });
    };

};

export const printForm = (rowData) => {
    //   console.log("rowData:", rowData);

    // Ensure rowData is an array and has at least one item
    if (!Array.isArray(rowData) || rowData.length === 0) {
        alert("No data available to print.");
        return;
    }

    // Start HTML content with the header and styles
    let htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="utf-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="description" content="">
          <meta name="author" content="">
          <title>ALIMCO</title>
          <style>
            @media print {
              @page {
                size: A4 portrait;
                margin: 50px;
              }
              .page-break {
                page-break-before: always;
              }
              .no-page-break {
                page-break-inside: avoid;
              }
            }
            body {
              font-family: Calibri, sans-serif;
              font-size: 14px;
              margin: 0;
              padding: 0;
            }
            .section {
              margin-bottom: 20px; /* Adjust if needed */
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            td {
              padding: 8px;
            }
          </style>
        </head>
        <body>
    `;

    // Loop through rowData to generate content for each row
    rowData.forEach((data, index) => {
        htmlContent += `
          <table
            cellpadding="0"
            cellspacing="0"
            id="body_TblHeader"
            style="
        border: solid 0px #000;
        font-family: Calibri;
        font-size: 14px;
        margin: auto;
        width: 800px;
      "
        >
            <tr>
                <td>
                    <table cellpadding="0" cellspacing="0" width="100%">
                        <tbody>
                            <tr>
                                <td colspan="3" style="text-align: center; padding-left: 10px">
                                    <!-- <strong style="font-size: 20px;"> -->
                                    <!-- भारतीय कृत्रिम अंग निर्माण कंपनी -->
                                    <!-- </strong> -->
                                    <br>
                                    <strong style="font-size: 20px;">
                                        ARTIFICIAL LIMBS MANUFACTURING CORPORATION OF INDIA
                                    </strong>
                                    <br>
                                    <!-- <strong style="font-size: 14px;"> -->
                                    <!-- (भारत सरकार का उपक्रम) -->
                                    <!-- </strong> -->
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style="border-bottom: 2px solid #333;padding-bottom: 5px;"
                                    >
                                        <tbody>
                                            <tr>
                                                <td style="text-align: left;">
                                                    <img src=${electricLogo} alt="Image" style="max-width: 100px; height: auto; display: block; margin-bottom: 5px;">
                                                    <br>
                                                    <strong style="font-size: 12px">
                                                        <!-- वेबसाइट/ Website: www.alimco.in -->
                                                        Website: www.alimco.in
                                                    </strong>
                                                    <br>
                                                    <strong style="font-size: 12px">
                                                        <!-- ईमेल / Email: -->
                                                        Email:
                                                    </strong>
                                                </td>
                                                <td style="text-align: center;">
                                                    <strong>(A GOVERNMENT OF INDIA UNDERTAKING)</strong>
                                                    <br>
                                                    <!-- <b> सामाजिक न्याय एवं अधिकारिता मंत्रालय के अधीन</b><br> -->
                                                    <strong>
                                                        UNDER MINISTRY OF SOCIAL, JUSTISE AND
                              EMPOWERMENT
                                                    </strong>
                                                    <br>
                                                    <!-- <b>आइ एस ओ 9001:2008 प्रतिष्ठान</b><br> -->
                                                    <strong>AN SO 9001:2008 COMPANY</strong>
                                                    <br>
                                                    <!-- जीO टीO रोड, कानपुर-209217 -->
                                                    <strong>G. T. ROAD, KANPUR-209217</strong>
                                                    <br>
                                                    <!-- टोल फ्री नंO/ -->
                                                    <strong>Toll Free No. 1800-180-5129</strong>
                                                </td>
                                                <td style="text-align: right;font-size: 12px;">
                                                    <img src=${watermark} alt="Image" style="align-content: right; max-width: 100px; height: auto; display: block; padding-left: 106px;">
                                                    <br>
                                                    <!-- <strong>फैक्स / FAX :0512-2770088</strong> -->
                                                    <strong> FAX :0512-2770088</strong>
                                                    <br>
                                                    <!-- <strong>दूरभाषा / Phone :0512-2770088</strong> -->
                                                    <strong> Phone :0512-2770088</strong>
                                                    <br>
                                                    <!-- <b>टिन नंO TIN No.-09645600010</b> -->
                                                    <b> TIN No.-09645600010</b>
                                                    <br>
                                                    <strong> CIN No. :U85110UP0972GOI003646</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: center; padding-left: 10px;padding-top: 8px;">
                                    <strong style="font-size: 20px">
                                        <!-- सर्विस रिपोर्ट -->
                                        SERVICE REPORT
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: right; padding-left: 10px">
                                    <strong style="font-size: 16px">
                                        <!-- दिनांक : 25/52/2024 -->
                                        Date :
                                    </strong><span>${data?.complaint_Date || ""
            }</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="width: 100%; padding: 0px 0px">
                                    <table cellpadding="0" cellspacing="0" width="100%">
                                        <tbody>
                                            <!-- 1 -->
                                            <tr>
                                                <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                    <!-- <b style="margin-right: 20px;">1.</b><strong style="margin-right: 20px;">लाभार्थी का नाम:</strong> <strong>___________________________________________________________________________________________</strong> -->
                                                    <b style="margin-right: 20px;">1.</b>
                                                    <strong style="margin-right: 0px;">Beneficiary Name:</strong>
                                                    <span>${data?.user?.name
            }</span>
                                                </td>
                                            </tr>
                                            <!-- 2 -->
                                            <tr>
                                                <td colspan="3" style="padding-bottom: 5px;padding-top: 6px;">
                                                    <!-- <b style="margin-right: 20px;">2.</b><strong style="margin-right: 20px;">स्थाई पता :</strong> <strong>________________________________________________________________________________________________</strong> -->
                                                    <b style="margin-right: 20px;">2.</b>
                                                    <strong style="margin-right: 0px;">Permanent Address:</strong>
                                                    <span>${data?.address || ""
            }</span>
                                                </td>
                                            </tr>
                                            <!-- 2 -->
                                            <tr>
                                                <td colspan="3" style="padding-bottom: 5px;padding-top: 6px;">
                                                    <!-- <b style="margin-right: 20px;">&nbsp;</b><strong style="margin-right: 20px;"></strong>
                                                
                                                    <strong style="margin-right: 0px;"></strong>
                                                    <strong></strong>
                                                </td>
                                            </tr>
                                            <!-- 2-->
                                            <tr>
                                                <td style="padding-top: 10px;padding-bottom: 10px;width: 40%;">
                                                    <!-- <b style="margin-right: 20px;">&nbsp;&nbsp;</b><strong style="margin-right: 20px;"> जिला  :</strong> <strong>__________________</strong> -->
                                                    <b style="margin-right: 20px;">&nbsp;&nbsp;</b>
                                                    <strong style="margin-right: 0px;"> District  :</strong>
                                                    <span>${data?.customer?.district
            }</span>
                                                </td>
                                                <td style="padding-top: 10px;padding-bottom: 10px;width: 30%;">
                                                    <!-- <b style="margin-right: 20px;"></b><strong style="margin-right: 20px;"> राज्य :</strong> <strong>__________________</strong> -->
                                                    <b style="margin-right: 5px;"></b>
                                                    <strong style="margin-right: 0px;"> State :</strong>
                                                    <span>${data?.customer?.state
            }</span>
                                                </td>
                                                <td style="padding-top: 10px;padding-bottom: 10px;text-align: right;">
                                                    <!-- <b style="margin-right: 20px;"></b><strong style="margin-right: 20px;">पिन  :</strong> <strong>__________________</strong> -->
                                                    <b style="margin-right: 5px;"></b>
                                                    <strong style="margin-right: 0px;">Pin Code :</strong>
                                                    <span>

                                                    ${data?.customer?.pin || ""}

                                                    </span>
                                                </td>
                                            </tr>
                                            <!-- 3 -->
                                            <tr>
                                                <td style="padding-top: 10px;padding-bottom: 10px;">
                                                    <!-- <b style="margin-right: 20px;">3.</b><strong style="margin-right: 20px;">मोबाइल नंबर :</strong> <strong>__________________</strong> -->
                                                    <b style="margin-right: 20px;">3.</b>
                                                    <strong style="margin-right: 0px;">Mobile No. :</strong>
                                                    <span>${data?.user?.mobile || ""
            }</span>
                                                </td>
                                                <td style="padding-top: 10px;padding-bottom: 10px;">
                                                    <!-- <b style="margin-right: 20px;"></b><strong style="margin-right: 20px;">आधार कार्ड नंबर :</strong> <strong>__________________</strong> -->
                                                    <b style="margin-right: 6px;"></b>
                                                    <strong style="margin-right: 0px;">Aadhaar Card No. :</strong>
                                                    <span>${data?.customer?.aadhaar ||
            ""
            }</span>
                                                </td>
                                            </tr>
                                            <!-- 4 -->
                                            <tr>
                                                <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                    <!-- <b style="margin-right: 20px;">4.</b><strong style="margin-right: 20px;">कैम्प का विवरण: :</strong>&nbsp;&nbsp;&nbsp;&nbsp; <strong style="margin-left: 15px;">एडिप<input type="checkbox">&nbsp;&nbsp;&nbsp;&nbsp;<strong style="margin-left: 15px;">एडिप<input type="checkbox">&nbsp;&nbsp;&nbsp;&nbsp;<strong style="margin-left: 15px;">एडिप<input type="checkbox">&nbsp;&nbsp;&nbsp;&nbsp;<strong style="margin-left: 15px;">एडिप<input type="checkbox">&nbsp;&nbsp;<strong style="margin-left: 15px;">एडिप<input type="checkbox">&nbsp;&nbsp;</strong> -->
                                                    <b style="margin-right: 20px;">4.</b>
                                                    <strong style="margin-right: 0px;"> Camp Details:</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                                                   

                                                                    <span>${data?.campName ||
            ""
            }</span>

                                                                </td>
                                                            </tr>
                                                            <!-- 5-->
                                                            <tr>
                                                                <td colspan="3" style="padding-bottom: 5px;padding-top: 5px;">
                                                                    <!-- <b style="margin-right: 20px;">5.</b><strong style="margin-right: 20px;">उत्पाद/प्रोडक्ट का कोड व नाम:</strong> <strong>______________________________________________________________________________</strong> -->
                                                                    <b style="margin-right: 20px;">5.</b>
                                                                    <strong style="margin-right: 0px;">Product code and name:</strong>

                                                                 ${data?.item_id ||
            ""
            }-${data?.item_name
            }
                                                                   
                                                                </td>
                                                            </tr>
                                                            <!-- 6 -->
                                                            <tr>
                                                                <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                                    <!-- <b style="margin-right: 20px;">6.</b><strong style="margin-right: 20px;">कैम्प का नाम एवं विवरण  :</strong><strong>__________________</strong> -->
                                                                    <b style="margin-right: 20px;">6.</b>
                                                                    <strong style="margin-right: 0px;">Camp Name and Details:</strong>

                                                                    <span>${data?.campName ||
            ""
            }</span>

                                                                </td>
                                                               
                                                            </tr>
<tr>
 <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                                    <!-- <b style="margin-right: 20px;"></b><strong style="margin-right: 20px;">वितरण तिथि व स्थान :</strong> <strong>__________________</strong> -->
                                                                    <b style="margin-right: 34px;"></b>
                                                                    <strong style="margin-right: 0px;"> Distribution Date and Place:</strong>
                                                                    <span>${data?.distribution_date ||
            ""
            } - ${data?.distribution_place || ""
            }</span>
                                                                </td>
</tr>


                                                            <!-- 7 -->
                                                            <tr>
                                                                <td style="padding-top: 10px;padding-bottom: 10px;">
                                                                    <!-- <b style="margin-right: 20px;">7.</b><strong style="margin-right: 20px;">  शिकायत की प्रवृति :</strong> <strong>__________________</strong> -->
                                                                    <b style="margin-right: 20px;">7.</b>
                                                                    <strong style="margin-right: 0px;"> Nature of Complaint:</strong>

                                                                    <span>${data?.problem ||
            ""
            }</span>

                                                                </td>
                                                                <td style="padding-top: 10px;padding-bottom: 10px;">
                                                                    <!-- <b style="margin-right: 20px;"></b><strong style="margin-right: 20px;">वारंटी के तहत :</strong> <strong>__________________</strong> -->
                                                                    <b style="margin-right: 6px;"></b>
                                                                    <strong style="margin-right: 0px;">Under Warranty : </strong>
                                                                    <span>${data?.warranty ==
                "1"
                ? "Yes"
                : "No"
            }</span>
                                                                </td>
                                                            </tr>
                                                            <!-- 8 -->
                                                            <tr>
                                                                <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                                    <b style="margin-right: 20px;">8.</b>
                                                                    <strong style="margin-right: 0px;">Repair details of components:</strong>

                                                                    <span>${data
                ?.ticketData
                ?.productLabel ||
            ""
            }</span>

                                                                    <!-- <b style="margin-right: 20px;">8.</b><strong style="margin-right: 20px;"> अवयवों की मरम्मत का विवरण::</strong> <strong>____________________________________________________________________________</strong> -->
                                                                </td>
                                                            </tr>
                                                            <!-- 9 -->
                                                            <tr>
                                                                <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                                    <!-- <b style="margin-right: 20px;">9.</b><strong style="margin-right: 20px;"> शिकायत सहायक/अटेन्डेन्टः </strong> <strong>______________________________________________________________________________</strong> -->
                                                                    <b style="margin-right: 20px;">9.</b>
                                                                    <strong style="margin-right: 0px;">  Complaint Assistant/Attendant:</strong>

                                                                    <span>${data?.attendant_name ||
            ""
            }</span>

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                                    <!-- <b style="margin-right: 30px;"></b><strong style="margin-right: 20px;">शिकायत का समाधान :</strong> <strong>____________________________________________________________________________________</strong> -->
                                                                    <b style="margin-right: 34px;"></b>
                                                                    <strong style="margin-right: 0px;">Complaint resolution:</strong>
                                                                    <span></span>
                                                                </td>
                                                            </tr>
                                                          
                                                                    <td colspan="3" style="padding-top: 10px;padding-bottom: 10px;">
                                                                      <strong style="margin-left: 0px;">Date: </strong>
                                                                            <span>${data?.ticket_close_date ||
            ""
            }</span></td></tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table cellpadding="2" cellspacing="0" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td style="text-align: left;height: 200px;vertical-align: bottom;">
                                                            <strong style="font-size: 12px">
                                                                <!-- (लाभार्थी का हस्ताक्षर) -->
                                                                (Signature of the beneficiary)
                                                            </strong>
                                                            <br>
                                                            <br>
                                                            <strong style="font-size: 12px">
                                                                <!-- स्थान :______________________ -->
                                                                Place :______________________
                                                            </strong>
                                                        </td>
                                                        <td style="text-align: center;height: 200px;vertical-align: bottom;">
                                                            <strong style="font-size: 12px">
                                                                <!-- विपणन अधिकारी के हस्ताक्षर  -->
                                                                Signature of Marketing Officer
                                                            </strong>
                                                            <br>
                                                            <br>
                                                            <strong style="font-size: 12px">
                                                                <!-- पद :______________________ -->
                                                                Designation :______________________
                                                            </strong>
                                                        </td>
                                                        <td style="text-align: right;height: 200px;vertical-align: bottom;">
                                                            <strong style="font-size: 12px;margin-right: 40px;">
                                                                <!-- (तकनीशियन के हस्ताक्षर)<br> -->
                                                                (Signature of Technician)
                                                            </strong>
                                                            <br>
                                                            <strong style="font-size: 12px">
                                                                <!-- मोबाइल नं.: :______________________ -->
                                                                Mobile No.:______________________
                                                            </strong>
                                                            <br>
                                                            <br>
                                                            <strong style="font-size: 12px;margin-right: 90px;">
                                                                <!-- कृते एलिम्को, कानपुर -->

                                                               ${data?.campVenue ||
            ""
            }

                                                            </strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
         
          ${index < rowData.length - 1 ? '<div class="page-break"></div>' : ""}
        `;
    });

    // Close HTML content
    htmlContent += `
        </body>
      </html>
    `;

    // Open a new window and print the document
    const printWindow = window.open("", "", "width=800,height=600");

    if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.onload = async () => {
            try {
                // Use html2canvas to capture the content as an image
                const canvas = await html2canvas(printWindow.document.body, {
                    scale: 2, // Adjust scale as needed for better resolution
                    useCORS: true, // Ensure that cross-origin images are handled
                });

                // Get the data URL of the canvas (image)
                const imageData = canvas.toDataURL("image/png");

                // Open the image in a new tab for demonstration (optional)
                const newTab = window.open();
                newTab.document.write('<img src="' + imageData + '"/>');

                // Trigger the print dialog after a short delay
                setTimeout(() => {
                    printWindow.print();
                }, 500); // Adjust delay if necessary
            } catch (error) {
                // console.error("Error capturing content with html2canvas:", error);
            }
        };

        // Trigger the print dialog
        printWindow.print();
    } else {
        alert(
            "Failed to open print window. Please check your browser's pop-up settings."
        );
    }
};

export const printGatePass = (
    row,
    aarsaData,
    paymentData,
    total,
    gst,
    data
) => {
    //   console.log(data, row, paymentData, aarsaData, "jjghgfhg");
    const toWords = new ToWords();
    const words = toWords.convert(data?.grand_total || 0.0, { currency: true });
    const ticketDetailsRows = row
        ?.map(
            (item, index) => `
      <tr key="${index}">
                                            <td
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                               ${index + 1}</td>
                                            <td
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                               ${item?.part_number}</td>
                                            <td
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                ${item?.description}</td>
                                            <td
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                 ${item?.hsn_code}</td>
                                            <td
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                ${item?.price}</td>
                                            <td
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                ${item?.quantity}</td>
                                            <td
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                 ${parseFloat(item.quantity * item.price).toFixed(2) || 0.00
                }</td>
                                        </tr>
  `
        )
        .join("");

    const totalAmount = row?.reduce(
        (total, item) => total + item.quantity * item.price,
        0
    );

    const printWindow = window.open();
    printWindow.document.write(
        `
      <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <title>ARTIFICIAL LIMBS MANUFACTURING CORPORATION OF INDIA</title>
    <style>
        @media print {
            button {
                display: none;
            }

            @page {
                size: A4 landscape;
                margin: 30px !important;
            }
        }
       
    </style>
</head>

<body>

    <table cellpadding="0" cellspacing="0" id="body_TblHeader"
        style="border: solid 0px #000; font-family: Calibri; font-size: 14px; margin: auto; width: 1160px !important;">
        <tr>
            <td colspan="4">
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                        <tr>
                            <td colspan="4">
                                    <table style="width: 100%;">
                                        <tbody>
                                            <td>
                                                  <img src=${logo} alt="alimco-logo"
                                            width="120px">
                                            </td>
                                            <td colspan="" style="text-align:center; padding-left: 10px;">
                                                <strong style="font-size: 24px;">ARTIFICIAL LIMBS MANUFACTURING CORPORATION OF
                                                    INDIA</strong><br>
                                                <strong style="font-size: 16px;padding-top: 10px;">
                                                    G.T.Road, Naramau.<br>
                                                    Postal Code: 209217, Uttar Pradesh, India.<br>
                                                    CIN: U85110UP1972NPL003646.<br>
                                                    GSTIN: 09AABCA8899F126.
                                                </strong>
                                            </td>
                                        </tbody>
                                    </table>
                               
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="text-align: right;padding-bottom: 10px;">
                                <strong>office Copy</strong>
                            </td>
                        </tr>
                        <tr>
                        <td>
                        <table cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                        <td colspan="3" style="vertical-align: top;width:80%">
                                <table cellpadding="0" cellspacing="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td colspan="3"
                                                style="border-top: 1px solid #000;border-bottom: 0px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: left;font-weight: 700;font-size: 27px;padding-left: 15px;">
                                                Returnable Gate Pass (RGP/VRGP)/Invoices
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="border-top: 1px solid #000;border-bottom: 0px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                From Location:</td>
                                            <td
                                                style="border-top: 1px solid #000;border-bottom: 0px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                To Location/Bill To</td>
                                            <td
                                                style="border-top: 1px solid #000;border-bottom: 0px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                Ship To:</td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="border-top: 1px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: left;font-weight: 700;padding-left: 10px;">
                                                 <span>${aarsaData?.address || ""
        }</span><br>
                                                <table cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>GSTIN/Unique ID</td>
                                                            <td>:</td>
                                                            <td>${aarsaData?.gstin ||
        ""
        }</td>

                                                        </tr>
                                                        <tr>
                                                            <td>State</td>
                                                            <td>:</td>
                                                            <td>${aarsaData?.state ||
        ""
        } </td>
                                                        </tr>
                                                        <tr>
                                                            <td>State Code</td>
                                                            <td>:</td>
                                                              <td>${aarsaData?.stateCode ||
        ""
        }</td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </td>
                                            <td
                                                style="border-top: 1px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: left;font-weight: 700;padding-left: 8px;">
                                                <span>${aarsaData?.address || ""
        }</span><br>
                                               
                                                <table cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>GSTIN</td>
                                                            <td>:</td>
                                                            <td>${aarsaData?.gstin ||
        ""
        }</td>

                                                        </tr>
                                                        <tr>
                                                            <td>State</td>
                                                            <td>:</td>
                                                            <td>${aarsaData?.state ||
        ""
        } </td>
                                                        </tr>
                                                        <tr>
                                                            <td>State Code</td>
                                                            <td>:</td>
                                                            <td>${aarsaData?.stateCode ||
        ""
        }</td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </td>
                                            <td
                                                style="border-top: 1px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;width: 28%;">
                                                 <span>                                        
Artificial Limbs Manufacturing Corporation of India G.T. Road, Kanpur - 209217  
                                                 </span><br>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td rowspan=""
                                style="border-top: 1px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 1px solid #000;text-align: left;font-size: 12px;font-weight: 600 ;padding-left: 10px;">
                                <table cellpadding="0" cellspacing="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="width: 100px;">SAP Doc. No </td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.sap_doc_no || "41513265465165 "}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                IEC Code
                                            </td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.iec_code || ""}</td>
                                        </tr>
                                        <tr>
                                            <td>Documant Date</td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.document_date || ""}</td>
                                        </tr>
                                        <tr>
                                            <td>Request By</td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.requested_by || ""}</td>
                                        </tr>
                                        <tr>
                                            <td>Mode of Transport</td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.mode_of_transport || ""
        }</td>
                                        </tr>
                                        <tr>
                                            <td>Vehicle No.</td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.vehicle_no || ""}</td>
                                        </tr>
                                        <tr>
                                            <td>Boxes/Packets</td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.boxe_or_packedts || ""
        }</td>
                                        </tr>
                                        <tr>
                                            <td>RGP/VRGP No.</td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.rgp_no || ""}</td>
                                        </tr>
                                        <tr>
                                            <td>Remarks</td>
                                            <td> : </td>
                                            <td  style="width: 150px;padding-left: 5px;">${row?.remark || ""}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                       
                        </tr>
                        </table>

                        </td>
                            </tr>

                        <tr>
                            <td colspan="4">
                                <table cellpadding="4" cellspacing="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                S.No</th>
                                            <th
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                Material No.</th>
                                            <th
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                Description</th>
                                            <th
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                HSN Code</th>
                                            <th
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                Price/Unit</th>
                                            <th
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 0px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                Quantity</th>
                                            <th
                                                style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 1px solid #000;text-align: center;font-weight: 700;">
                                                Total (INR)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        ${ticketDetailsRows}
                                        
                                        
                                    </tbody>

                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <table cellpadding="0" cellspacing="0" width="100%">
                                    <tr>
                                        <td
                                            style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 1px solid #000;text-align: left;font-weight: 700; padding-left: 8px;width: 764px;">
                                            <table cellpadding="0" cellspacing="0" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>Total Value of Invoice (in Words)</td>
                                                        <td>:</td>
                                                        <td>
                                                         ${words}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Payment Terms</td>
                                                        <td>:</td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Incoterms</td>
                                                        <td>:</td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>

                                        <td
                                            style="text-align: right;vertical-align: top;border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 0px solid #000;text-align: left;font-weight: 700; padding-left: 8px;width: 764417;">
                                           
                                                <table style="width: 100%;">
                                                    <tbody>
                                                     <tr>
                                                        <td>GST</td>
                                                        <td>:</td>
                                                        <td>${RUPEES_SYMBOL} 0.00

                                                        </td>
                                                    </tr>
                                                        <tr>
                                                            <td>Total Value</td>
                                                            <td>:</td>
                                                            <td>${RUPEES_SYMBOL} ${data?.grand_total || 0.00
        }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>


                        </tr>
                        <tr>
                            <td colspan="4">
                                <table cellpadding="0" cellspacing="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td
                                            style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 1px solid #000;text-align: left;font-weight: 700;padding: 5px;">
                                                <strong>Terms and Conditions</strong><br>
                                                <span>1. The above materials are on loan basis.</span><br>
                                                <span>2. The materials should be returned within 30 days.</span><br>
                                                <span>3. Claims for pricing errors, shortages, and defective products must
                                                    be reported within 15 days of the invoice date.</span><br><br>
                                                <span>Declaration: This is to certify that particulars given in the invoice
                                                    are true & correct.</span>
                                            </td>
                                            
                                            <td rowspan="2"
                                            style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 0px solid #000;text-align: center;font-weight: 700;vertical-align: top;padding: 5px;">
                                               <strong style="margin-top: 10px;"> FOR ARTIFICIAL LIMBS MANUFACTURING
                                                CORPORATION OF INDIA</strong><br>
                                                <br>
                                                <br>
                                                <br>
                                                <span style="text-align: center;">Authorized Signatory</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                            style="border-top: 0px solid #000;border-bottom: 1px solid #000;border-right: 1px solid #000;border-left: 1px solid #000;text-align: left;font-weight: 700;padding: 5px;">
                                                Declaration. This is to cortify that particulars given in the Invoice
                                                are True & Correct directly/ indirectly from the buyer. and the amount
                                                indicated represents the price actually charged and there is no inllow
                                                of additional consideration
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>


    </table>
</body>

</html>
      `
    );
    printWindow.document.close(); // necessary for some browsers to finish writing before printing
    setTimeout(() => {
        printWindow.print();
        printWindow.close();
    }, 100)
};

export const downloadCredit = (row) => {
    const toWords = new ToWords();
    const a = parseFloat(row?.totalGstPricePartial || 0).toFixed(2);
    const b = parseFloat(row?.creditnotetotal || 0).toFixed(2);
    const totalGstPrice = (parseFloat(a) + parseFloat(b)).toFixed(2);

    const words = toWords.convert(totalGstPrice || 0.00, { currency: true });
    //   console.log(totalGstPrice,a,b,"net Total");

    const productDetailsRows = row
        ?.stockData?.items?.map(
            (item, index) => `

     <tr  key="${index}"
                                    style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 40px;">
                                    <td
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                        ${index + 1}</td>
                                    <td
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                       ${item?.sparePartPartial?.hsn_code || " "
                }<br> ${item?.sparePartPartial?.part_name || " "
                } </td>
                                    <td
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
        ${item?.item_name}</td>
                                    <td
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                       ${item?.quantity}</td>
                                    <td
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                         ${item?.price || 0.00}</td>
                                    <td
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                           ${item?.sparePartPartial?.gst || 0.00}
                                        </td>
                                    <td
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 0px solid #333;text-align: center;">
                                        ${parseFloat(item?.quantity * item?.price).toFixed(2) || 0.00}</td>

                                </tr>
`
        )
        .join("");

    ;
    const content =
        `

<!doctype html>
<html>

<head>
    <meta charset="utf-8">
    <title>Artificial Limbs Manufacturing Corporation of India</title>
</head>
<style type="text/css">
    p {
        padding: 0px;
        margin: 0px;
        margin-bottom: 10px !important;
    }
</style>

<body style="margin: 10px 0; font-size: 12px;line-height: 16px; ">
    <table
        style="width: 800px; border: 1px solid black; margin: 0px auto; font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif"
        cellpadding="0" cellspacing="0">
        <tr>
            <td>
                <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">

                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%; border-bottom: 1px solid black;">
                                <tr>
                                    <td style="width: 20%; text-align: center;">
                                        <img src=${logo}
                                            alt="alimco-logo" width="100px"><br>
                                        <span>ISO 9001 : 2015</span>
                                    </td>
                                    <td style="padding-top: 10px;text-align: center;">
                                        <span
                                            style="font-size: 20px; font-weight: bolder; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">Artificial
                                            Limbs Manufacturing Corporation of India</span><br>
                                        <span><b
                                                style="font-size:medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">A
                                                GOVERMENT OF INDIA UNDERTAKING</b></span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;">
                                            Naramau, G.T.Road Kanpur 209217</span><br>
                                        <span
                                            style="font-family:Verdana, Geneva, Tahoma, sans-serif;">U85110UP1972NPL003646</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;">09AABCA8899F1Z6
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-size: 16px; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">TAX
                                                INVOICE</span><br><br>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td colspan="2" style="text-align: center;"><b>TAX INVOICE</b></td>
                                </tr> -->
                                <tr>
                                    <td colspan="2">
                                        <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                                            <tr>
                                                <td><strong style="margin-left: 5px;">Type :</strong> 07AAHCK3696C1ZF
                                                </td>
                                                <td style="text-align: right;"><strong style="margin-right: 5px;">SAP
                                                        Reference No :</strong><span
                                                        style="padding-right: 5px;">4865789646</span></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>


                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 0px solid black;">
                                <tbody>
                                    <tr>
                                        <th style="width: 300px;text-align: left;padding-left: 5px;">
                                            IRN</th>
                                        <th style="width: 250px;text-align: center;">
                                            Acknowledgment Number</th>
                                        <th style="width: 300px;text-align: right;padding-right: 5px;">
                                            Ack.Date</th>

                                    </tr>
                                    <tr>
                                        <td
                                            style="width: 250px;text-align: left;padding-left: 5px;border-top: 1px solid #333;">
    ${row?.inr || ""}</td>
                                        <td style="width: 250px;text-align: center;border-top: 1px solid #333;">
                                           ${row?.Ack_No || ""} </td>
                                        <td
                                            style="width: 300px;text-align: right;padding-right: 5px;border-top: 1px solid #333;">
                                           ${row?.Ack_Date || ""}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 1px solid black;">
                                <tbody>
                                    <tr>
                                        <th style="width: 200px;text-align: left;padding-left: 5px;">
                                            Credit No</th>
                                        <th style="width: 250px;text-align: center;">
                                            Credit Date </th>
                                        <th style="width: 300px;text-align: center;padding-right: 5px;">
                                            Order Ref No </th>
                                        <th style="width: 300px;text-align: left;padding-left: 5px;">
                                            Order Ref Date</th>
                                        <th style="width: 250px;text-align: center;">
                                            MRN No </th>
                                        <th style="width: 300px;text-align: right;padding-right: 5px;">
                                        MRN Date</th>

                                    </tr>
                                    <tr>
                                        <td style="width: 200px;text-align: left;padding-left: 5px;">
                                            ${row?.credit_no || ""}</td>
                                        <td style="width: 250px;text-align: center;">
                                        ${row?.credit_date || ""}</td>
                                        <td style="width: 300px;text-align: center;padding-right: 5px;">
                                            ${row?.order_ref_no || ""} </td>
                                        <td style="width: 250px;text-align: left;padding-left: 5px;">
                                           ${row?.order_ref_date || ""} </td>
                                        <td style="width: 250px;text-align: center;">
                                           ${row?.MRN_no || ""} </td>
                                        <td style="width: 300px;text-align: right;padding-right: 5px;">
                                            ${row?.MRN_date || ""}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 1px solid black;">
                                <tr>
                                    <th
                                        style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: medium; width: 50%; padding: 5px;">
                                        Shipping /Export Details (Consingee Address):</th>
                                    <th
                                        style="font-size: medium; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; width: 50%;">
                                        Billing Details (Indenter Code & Name):</th>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%;">
                                <tr>
                                    <td
                                        style="line-height: 20px; border-right: 1px solid black; padding: 5px 0px 0px 5px;border-bottom: 1px solid #333;">
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Name
                                                :</b> ${row?.aasra?.name || ""}
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Address
                                                :</b>  ${row?.aasra?.address || ""
        }</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Pincode
                                                :</b>
                                             ${row?.aasra?.pin || ""}</span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Phone
                                                :</b>  ${row?.aasra?.mobile_no || ""
        }</span><br>

                                    </td>
                                    <td
                                        style="line-height: 20px; width: 50%; padding: 5px 0px 0px 5px;border-bottom: 1px solid #333;">
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Party
                                                Code :</b>  ${row?.aasra?.unique_code || ""
        } </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">Indentor
                                                Name :</b>${row?.aasra?.name || ""
        }
                                        </span><br>
                                        <span style="font-family:Verdana, Geneva, Tahoma, sans-serif;"><b
                                                style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: small;">GSTIN
                                                :</b>${row?.aasra?.gstIn || ""}
                                        </span><br>

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 0px solid black;">
                                <tbody>
                                    <tr>
                                        <th
                                            style="width: 200px;text-align: left;padding-left: 5px;border-bottom: 1px solid #333;">
                                            Banker's Name</th>
                                        <th style="width: 250px;text-align: center;border-bottom: 1px solid #333;">
                                            Pyament Terms </th>
                                        <th
                                            style="width: 300px;text-align: center;padding-right: 5px;border-bottom: 1px solid #333;">
                                            Transporter Name </th>
                                        <th
                                            style="width: 300px;text-align: left;padding-left: 5px;border-bottom: 1px solid #333;">
                                            Truck No </th>
                                        <th style="width: 250px;text-align: center;border-bottom: 1px solid #333;">
                                            No of Cases </th>
                                        <th
                                            style="width: 300px;text-align: right;padding-right: 5px;border-bottom: 1px solid #333;">
                                            Weight</th>

                                    </tr>
                                    <tr>
                                        <td style="width: 200px;text-align: left;padding-left: 5px;">
                                            ${row?.bank_name || ""}</td>
                                        <td style="width: 200px;text-align: left;padding-left: 5px;">
                                            ${row?.pyament_terms || ""}</td>
                                        <td style="width: 250px;text-align: center;">
                                            ${row?.transporter_name || ""} </td>
                                        <td style="width: 300px;text-align: center;padding-right: 5px;">
                                            ${row?.truck_no || ""} </td>
                                        <td style="width: 250px;text-align: left;padding-left: 5px;">
                                            ${row?.no_of_cases || ""}</td>
                                        <td style="width: 250px;text-align: center;">
                                           ${row?.weight || ""} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 0px solid black;">
                                <tbody>
                                    <tr>
                                        <th
                                            style="width: 200px;text-align: left;padding-left: 5px;border-bottom: 1px solid #333">
                                            Gate Pass No </th>
                                        <th style="width: 250px;text-align: center;border-bottom: 1px solid #333">
                                            Rr Gr No </th>
                                        <th style="text-align: center;padding-right: 5px;border-bottom: 1px solid #333">
                                            Remarks </th>
                                        <th style="text-align: left;padding-left: 5px;border-bottom: 1px solid #333">
                                            Driver Contact No</th>
                                        <th colspan="2" style="text-align: center;border-bottom: 1px solid #333">
                                            Dispatch Mode </th>


                                    </tr>
                                    <tr>
                                        <td style="width: 200px;text-align: left;padding-left: 5px;">
                                           ${row?.gate_pass || ""}</td>
                                        <td style="width: 250px;text-align: center;">
                                           ${row?.rg_gr_no || ""}</td>
                                        <td style="text-align: center;padding-right: 5px;">
                                            ${row?.remarks || ""}</td>
                                        <td style="text-align: center;padding-left: 5px;">
                                           ${row?.driver_contact || ""} </td>
                                        <td colspan="2" style="text-align: center;">
                                            ${row?.dispatch_mode || ""} </td>

                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0"
                                style="width: 100%; text-align: left; border-bottom: 1px solid black;">
                                <tbody>
                                    <tr>
                                        <th style="text-align: center;padding-left: 5px;border-bottom: 1px solid #333">
                                            Insurance Details
                                        </th>

                                    </tr>
                                    <tr>
                                        <td style="text-align: left;padding-left: 5px;">
                                            ${row?.notes || " "}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" style="width: 100%; border-bottom: 0px solid black;">
                                <tr
                                    style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                        S.NO</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                        Product & HSN</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                        Nomenclature</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                        QTY</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                        Unit Price</th>
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 1px solid #333;text-align: center;">
                                        GST (%)</th>                              
                                    <th
                                        style="border-top: 0px solid #afadad;border-bottom: 1px solid #333;border-left: 0px solid #afadad;border-right: 0px solid #333;text-align: center;">
                                        Total Amount</th>
                                </tr>

                               ${productDetailsRows}
                            </table>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadding="0" style="width: 100%; border-bottom: 0px solid black;">
                                <tr>
                                    <td style="border-right: 1px solid black;">
                                        <h3
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-weight: bolder; margin: 0; padding: 0;">
                                            TERMS & CONDITIONS FOR THE SALE:</h3>
                                        <ol
                                            style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: small;">
                                            <li>Interest of 21% on annual basis will be levied if the invoice is not
                                                cleared within 8 days of presentation of documents.</li>
                                            <li>Items once sold not be accepted back.</li>
                                            <li>Rest of the terms & conditions as per our quotation.</li>
                                        </ol>
                                        </span>
                                    </td>
                                    <td style="line-height: 20px; width: 25%; text-align: end;">
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">GST
                                         :</span><br>
                                    </td>
                                    <td style="line-height: 20px; width: 25%; text-align: center;"
                                        style="width: 25%; text-align: center; vertical-align: text-top; font-size: small; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                                        <span
                                            style="font-size: small; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">₹

                                            ${parseFloat(row?.totalGstPricePartial).toFixed(2) || 0.00}</span><br>


                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2"
                                        style="border-top: 1px solid #333;border-bottom: 0px solid #333;border-left: 0px solid #afadad;border-right: 0px solid #333;text-align: right;font-weight: 600; font-size: 14px;padding-bottom: 5px;padding-top: 2px">
                                        Net Total
                                    </td>
                                    <td
                                        style="border-top: 1px solid #333;border-bottom: 0px solid #333;border-left: 0px solid #afadad;border-right: 0px solid #333;text-align: center;font-weight: 600; font-size: 14px;padding-bottom: 5px;padding-top: 2px">
                                        ₹ ${parseFloat(totalGstPrice).toFixed(2) || 0.00}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style="border-top: 0px solid #333;border-bottom: 0px solid #333;border-left: 0px solid #afadad;border-right: 0px solid #333;text-align: left;padding-left: 10px;">
                                        Form No. ER 35
                                    </td>
                                    <td colspan="2"
                                        style="border-top: 0px solid #333;border-bottom: 0px solid #333;border-left: 0px solid #afadad;border-right: 0px solid #333;text-align: center;">
                                        <strong>${words}</strong>
                                    </td>
                                </tr>
                                <tr>

                                    <td colspan="3"
                                        style="border-top: 1px solid #333;border-bottom: 0px solid #333;border-left: 0px solid #afadad;border-right: 0px solid #333;text-align: right;padding-right: 10px;">
                                        <br><span>E. & O.E.</span><br><br>
                                        <span>For ALIMCO</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td style="height: 100px;vertical-align: bottom;">
                            <table cellpadding="0" cellspacing="0" style="width: 100%;padding-bottom: 10px;">
                                <tr>
                                    <td style="padding-left: 50px;"><strong>Status : Active</strong></td>
                                    <td style="text-align: center;"><strong>Prepared By</strong></td>
                                    <td style="text-align: center;"><strong>(Authorized Signature)</strong></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>
</html>
 `
        ;

    const printWindow = window.open("", "", "width=800,height=600");
    // Open a new window to render the content
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
    // Wait for the content to load
    printWindow.onload = async () => {
        // Capture the content with html2canvas
        const canvas = await html2canvas(printWindow.document.body, {
            scale: 1, // Increase scale for better resolution
        });
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "a4");

        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", 10, 10, 580, 0);

        const fileName = "Credit Note";

        pdf.save(fileName);
        // Close the print window
        printWindow.close();
    };



};
