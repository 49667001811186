import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import MySvg from "../../Components/Common/Component/MySvg";
import CustomizerContext from "../../_helper/Customizer";
import { dashboardAPI } from "../../api/user";
import useLogout from "../../util/useLogout";

const SidebarMenuItems = ({
  setMainMenu,
  sidebartoogle,
  setNavActive,
  activeClass,
}) => {
  const [menu, setMenu] = useState([]);
  const [icons, setIcons] = useState([]); // State to store icons
  const [isLoading, setIsLoading] = useState(true);
  const logout = useLogout();
  const fetch = async () => {
    try {
      const response = await dashboardAPI();
      if (response.data.status === "success") {
        setMenu(response.data.data.sideBar);
        // Create an array of icons
        const iconList = response.data.data.sideBar.flatMap((item) =>
          item.Items.map((subItem) => subItem.icon)
        );
        setIcons(iconList);
         setIsLoading(false);
      } else if (response.data.status == "failed") {
        setIsLoading(false);
        toast.error(response.data.message);
      } else if (response.data.status == "expired") {
        logout(response.data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const { layout } = useContext(CustomizerContext);
  const layout1 = localStorage.getItem("sidebar_layout") || layout;
  const [, setDummy] = useState(0);

  const forceRerender = () => {
    setDummy((prev) => prev + 1);
  };

  const id = window.location.pathname.split("/").pop();
  const layoutId = id;
  const CurrentPath = window.location.pathname;
  const { t } = useTranslation();

  const toggletNavActive = (item) => {
    const pageHeader = document.querySelector(".page-header");
    const sidebarWrapper = document.querySelector(".sidebar-wrapper");
    const megaMenuContainer = document.querySelector(".mega-menu-container");

    if (window.innerWidth <= 991) {
      if (pageHeader) {
        pageHeader.className = "page-header close_icon";
      }
      if (sidebarWrapper) {
        sidebarWrapper.className = "sidebar-wrapper close_icon";
      }
      if (megaMenuContainer) {
        megaMenuContainer.classList.remove("d-block");
      }

      if (item.type === "sub") {
        if (pageHeader) {
          pageHeader.className = "page-header";
        }
        if (sidebarWrapper) {
          sidebarWrapper.className = "sidebar-wrapper";
        }
      }
    }

    if (!item.active) {
      menu?.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }

    item.active = !item.active;
    setMainMenu({ mainmenu: menu || "" });
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          color="primary"
          style={{
            height: "3rem",
            width: "3rem",
            marginLeft: "100px",
          }}
          type="grow"
        >
          Loading...
        </Spinner>
      ) : (
        <>
          {menu?.map((Item, i) => (
            <Fragment key={i}>
              <li className="sidebar-main-title">
                {/* Render the icon parallel to the title */}
                {/* {icons[i] && (
                     <MySvg IconComponent={Item[i]} color="blue" size="2x" />
                    )} */}
                <div>
                  <h6 className="lan-1">{t(Item.menutitle)}</h6>
                </div>
              </li>
              {Item?.Items.map((menuItem, i) => (
                <li className="sidebar-list" key={i}>
                  {menuItem.type === "sub" ? (
                    <a
                      href="javascript"
                      className={`sidebar-link sidebar-title ${
                        CurrentPath.includes(menuItem.title.toLowerCase())
                          ? "active"
                          : ""
                      } ${menuItem.active && "active"}`}
                      onClick={(event) => {
                        event.preventDefault();
                        setNavActive(menuItem);
                        activeClass(menuItem.active);
                      }}
                    >
                      <i className={menuItem.icon} aria-hidden="true" ></i>
                      {"       "}&nbsp;
                      <span>{t(menuItem.title)}</span>
                      {menuItem.badge ? (
                        <label className={menuItem.badge}>
                          {menuItem.badgetxt}
                        </label>
                      ) : (
                        ""
                      )}
                      <div className="according-menu">
                        {menuItem.active ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-right"></i>
                        )}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}

                  {menuItem.type === "link" ? (
                    <Link
                      to={menuItem.path}
                      className={`sidebar-link sidebar-title link-nav  ${
                        CurrentPath.includes(menuItem.path.toLowerCase())
                          ? "active"
                          : ""
                      }`}
                      onClick={() => toggletNavActive(menuItem)}
                    >
                      
                      <i className={menuItem.icon} aria-hidden="true" ></i>
                      {"         "}&nbsp;
                      <span>{t(menuItem.title)}</span>
                      {menuItem.badge ? (
                        <label className={menuItem.badge}>
                          {menuItem.badgetxt}
                        </label>
                      ) : (
                        ""
                      )}
                    </Link>
                  ) : (
                    ""
                  )}

                  {menuItem.children ? (
                    <ul
                      className="sidebar-submenu"
                      style={
                        layout1 !== "compact-sidebar compact-small"
                          ? menuItem?.active ||
                            CurrentPath.includes(menuItem?.title?.toLowerCase())
                            ? sidebartoogle
                              ? {
                                  opacity: 1,
                                  transition: "opacity 500ms ease-in",
                                }
                              : { display: "block" }
                            : { display: "none" }
                          : { display: "block" }
                      }
                    >
                      {menuItem.children.map((childrenItem, index) => {
                        return (
                          <li key={index}>
                            {childrenItem.type == "sub" ? (
                              <a
                                href="javascript"
                                className={`${
                                  CurrentPath.includes(
                                    childrenItem?.path?.toLowerCase()
                                  )
                                    ? "active"
                                    : ""
                                }`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  toggletNavActive(childrenItem);
                                }}
                              >
                                {t(childrenItem.title)}
                                <span className="sub-arrow">
                                  <i className="fa fa-chevron-right"></i>
                                </span>
                                <div className="according-menu">
                                  {childrenItem.active ? (
                                    <i className="fa fa-angle-down"></i>
                                  ) : (
                                    <i className="fa fa-angle-right"></i>
                                  )}
                                </div>
                              </a>
                            ) : (
                              ""
                            )}

                            {childrenItem.type === "link" ? (
                              <Link
                                to={childrenItem.path}
                                className={`${
                                  CurrentPath.includes(
                                    childrenItem?.path?.toLowerCase()
                                  )
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => toggletNavActive(childrenItem)}
                              >
                                {t(childrenItem.title)}
                              </Link>
                            ) : (
                              ""
                            )}

                            {childrenItem.children ? (
                              <ul
                                className="nav-sub-childmenu submenu-content"
                                style={
                                  CurrentPath.includes(
                                    childrenItem?.title?.toLowerCase()
                                  ) || childrenItem.active
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                {childrenItem.children.map(
                                  (childrenSubItem, key) => (
                                    <li key={key}>
                                      {childrenSubItem.type === "link" ? (
                                        <Link
                                          to={childrenSubItem.path}
                                          className={`${
                                            CurrentPath.includes(
                                              childrenSubItem?.path?.toLowerCase()
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            toggletNavActive(childrenSubItem)
                                          }
                                        >
                                          {t(childrenSubItem.title)}
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default SidebarMenuItems;
